import React from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
// import {PrettyPrintJson}  from '../../services/Utils';

import "./UserClassification.css";
import CN from '../../assets/chn_flag.png';
import HK from '../../assets/hk_flag.png';
import Other from '../../assets/other_flag.png';

const UserClassification = (props) => {

    const { userRef, setUserRef } = useUserSettings();
    const { goToNamedStep } = props;

    const changeIDCardOrigin = (o) => {
        setUserRef({ ...userRef, IDCardOrigin: o });
        goToNamedStep("PersonalInfo");
    };

    return (
        <StepContainer showNavigation={false} {...props}>
            <div className="row">
                <div className="col-12">
                    <h3 className="pt-3"> 請選擇證件 </h3>
                </div>

                {/* {userRef.IPRegion !== "CN" && ( */}
                <div className="col-lg-6 col-xs-12 p-2 px-5">
                    <button type="button" className="btn btn-idcard"
                        onClick={() => changeIDCardOrigin("CHN")}
                    >
                        <div className="my-lg-5">
                            <img className="flag my-1" src={CN} alt="" />
                            <br />
                            <span className="text-center"> 中國內地身份證 </span>
                        </div>
                    </button>
                </div>
                {/* )} */}

                <div className="col-lg-6 col-xs-12 p-2 px-5">
                {/* <div className="col-lg-12 col-xs-12 p-2 px-5"> */}
                    <button type="button" className="btn btn-idcard"
                        onClick={() => changeIDCardOrigin("HKG")}
                    >
                        <div className="my-lg-5">
                            <img className="flag my-1" src={HK} alt="" />
                            <br />
                            <span className="text-center"> 中國香港身份證 </span>
                        </div>
                    </button>
                </div>

                
                <div className="col-lg-6 col-xs-12 p-2 px-5">
                    <button type="button" className="btn btn-idcard btn-idcard-other"
                        onClick={() => changeIDCardOrigin("OTHER")}
                    >
                        <div className="my-lg-5">
                            <img className="flag my-1" src={Other} alt="" />
                            <br />
                        <span className="text-center"> 其他國家及地區證件 </span>
                        </div>
                    </button>
                </div>
                

                {/* <div className="col-lg-12 col-xs-12 p-2 px-5">
                    <button type="button" className="btn btn-idcard btn-idcard-other"
                        onClick={() => changeIDCardOrigin("OTHER")}
                    >
                        <span className="text-center"> 其他國家證件 </span>
                    </button>
                </div> */}
            </div>
        </StepContainer>
    );
}

export default UserClassification;
