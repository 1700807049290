import React, { useEffect, useState } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import { FBLabel, InvalidFieldStyle, PrettyPrintJson, cssFormInput } from '../../services/Utils';
import { RadioButtonList } from "../FFGCheckbox/FFGCheckbox";
import { ButtonWrapper, ButtonCheckbox, ButtonLabel } from "../FFGAccountInfo/FFGAccountStyle";
import { SwitchBoxWapper, SwitchBox, SwitchBoxLabel } from "../SelfDisclosure/SwitchBoxStyle";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select, { components, createFilter } from 'react-select';

const FinancialInfo = (props) => {
    const { userRef } = useUserSettings();
    const [Accounts, setAccount] = useState();
    const [InvestExpTypes, setInvestExpTypes] = useState([]);

    Yup.addMethod(Yup.array, "investmentExpRequired", function (errorMessage) {
        return this.test(`test-investment-exp`, errorMessage, function (value) {
            const { path, createError } = this;
            return (
                (value && value.some(w => w.Year > 0)) ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const validationSchema = Yup.object().shape({
        FundSourceSalary: Yup.boolean(),
        FundSourceInvestment: Yup.boolean(),
        FundSourceFamily: Yup.boolean(),
        FundSourceRetire: Yup.boolean(),
        FundSourceProfit: Yup.boolean(),
        FundSourceSaving: Yup.boolean(),
        FundSourceOther: Yup.boolean(),
        FundSourceOtherDesc: Yup.string().nullable()
            .when('FundSourceOther', (val, schema) => {
                return val ?
                    schema.required("請註明其它收入來源") : schema.nullable().notRequired();
            }),
        AssetProperty: Yup.boolean(),
        AssetCash: Yup.boolean(),
        AssetSecurities: Yup.boolean(),
        AssetOther: Yup.boolean(),
        AssetOtherDesc: Yup.string().nullable()
            .when('AssetOther', (val, schema) => {
                return val ?
                    schema.required("請註明其它資產分類") : schema.nullable().notRequired();
            }),

        Income: Yup.string().nullable().required("請選擇每年收入"),
        NAV: Yup.string().nullable().required("請選擇資產分類"),
        Turnover: Yup.string().nullable().required("請選擇總資產淨值"),
        NoOfTrade: Yup.string().nullable().required("請選擇每年交易次數"),
        InvestGoal: Yup.string().nullable().required("請選擇投資目標"),
        HasInvestExperience: Yup.boolean(), //.required("請選擇是否有投資經驗"),
        InvestmentExperienceTypes: Yup.array().nullable(),
        //     .when('HasInvestExperience', (val, schema) => {
        //         return val ?
        //             schema.investmentExpRequired("請填上投資經驗") : schema;
        //     }),

        DerivativeTrade: Yup.boolean(),

        DerivativeCourse: Yup.boolean(),
        CourseName: Yup.string().nullable()
            .when('DerivativeCourse', (val, schema) => {
                return val ? schema.required("請填上課程/研討會名稱") : schema;
            }),
        CourseTrainer: Yup.string().nullable()
            .when('DerivativeCourse', (val, schema) => {
                return val ? schema.required("請填上主辦機構") : schema;
            }),
        CourseYear: Yup.string().nullable()
            .when('DerivativeCourse', (val, schema) => {
                return val ? schema.required("請填上參與年份") : schema;
            }),

        DerivativeWorkingExp: Yup.boolean(),
        DerivativeWorkCompany: Yup.string().nullable()
            .when('DerivativeWorkingExp', (val, schema) => {
                return val ? schema.required("請填上任職公司名稱") : schema;
            }),
        DerivativeWorkPosition: Yup.string().nullable()
            .when('DerivativeWorkingExp', (val, schema) => {
                return val ? schema.required("請填上職位") : schema;
            }),

        /*
        IsInterestedInDerivativeProducts: Yup.boolean().nullable()
            .when('DerivativeTrade', (val, schema) => {
                // if client has no experience & want to open derivative accounts, he/she has be be IsInterestedInDerivativeProducts
                return (userRef?.FBAcc.includes("F","O","UO") && !HasDerivativeTradeExp()) ? schema.oneOf([true], "") : (val || !userRef?.FBAcc.includes("F","O","UO")) ? schema : schema.oneOf([true], "");
            }),
        */
    });


    const { register, trigger, watch, getValues, setError, setValue, handleSubmit, reset, control,
        formState: { isValid, errors }, clearErrors } = useForm({
            mode: "all",
            shouldFocusError: true,
            resolver: yupResolver(validationSchema),
            defaultValues: {
            }
        });

    const watchAllFields = watch();

    useEffect(() => {
        AOSServices.GetFinancialInfo(userRef.RefCode)
            .then((resp) => {
                if (resp && resp.Result) {
                    reset(resp.Info);
                    setInvestExpTypes(resp.Info.InvestmentExperienceTypes);
                }
            });
    }, [reset]);


    const goNext = () => {
        clearErrors();
        if (!isValid) {
            trigger(undefined, { shouldFocus: true });
            return new Promise(resolve => resolve(false));
        } else {
            const data = getValues();
            if(userRef?.FBAcc.includes("F","O","UO") && 
                (
                    (!HasDerivativeTradeExp() && data.IsInterestedInDerivativeProducts != true) ||
                    (HasDerivativeTradeExp() && !data.DerivativeTrade && data.IsInterestedInDerivativeProducts != true)
                )
            )
            {
                setError("IsInterestedInDerivativeProducts", {
                    type: "manual",
                    //message: "如閣下没有意向買賣衍生工具產品，將不能交易以下產品(包括但不限於︰牛熊證﹑衍生認股證﹑期貨及期權﹑對沖基金﹑結構性產品等)及不能開立衍生工具相關的戶口(包括但不限於︰期貨及期權戶口)"
                    message: "由於閣下沒有填寫相關產品投資經驗及表示沒有興趣買賣衍生工具產品，因此本公司不能接納您的期貨/期權開戶申請。如需修正，請重新填寫。"
                });
                return new Promise(resolve => resolve(false));
            } 
            
            if (!data.FundSourceSalary &&
                !data.FundSourceInvestment &&
                !data.FundSourceFamily &&
                !data.FundSourceRetire &&
                !data.FundSourceProfit &&
                !data.FundSourceSaving &&
                !data.FundSourceOther) {
                    // console.log("FundSource", (!data.FundSourceSalary &&
                    //     !data.FundSourceInvestment &&
                    //     !data.FundSourceFamily &&
                    //     !data.FundSourceRetire &&
                    //     !data.FundSourceProfit &&
                    //     !data.FundSourceSaving &&
                    //     !data.FundSourceOther));

                setError("FundSource", {
                    type: "manual",
                    message: "請選擇收入來源"
                });
                return new Promise(resolve => resolve(false));

            } else if (!data.AssetProperty &&
                !data.AssetCash &&
                !data.AssetSecurities &&
                !data.AssetVehicle &&
                !data.AssetOther) {
                setError("Asset", {
                    type: "manual",
                    message: "請選擇資產分類"
                });
                return new Promise(resolve => resolve(false));
            } else {
                return new Promise(resolve => {

                    // sync properties values
                    data.HasInvestExperience = data.InvestmentExperienceTypes.some(w => w.Year !== 0);

                    //"InvestExperienceStock", "InvestExperienceWarrant", "InvestExperienceFutures", "InvestExperienceMutualFund"

                    var idx_Warrant = data.InvestmentExperienceTypes.findIndex(w=>w.PropName=="InvestExperienceWarrant");
                    var idx_CCB = data.InvestmentExperienceTypes.findIndex(w=>w.PropName=="InvestExperienceCBBC");

                    data.InvestmentExperienceTypes[idx_CCB].Year = data.InvestmentExperienceTypes[idx_Warrant].Year;

                    var idx_MFund = data.InvestmentExperienceTypes.findIndex(w=>w.PropName=="InvestExperienceMutualFund");
                    var idx_HFund = data.InvestmentExperienceTypes.findIndex(w=>w.PropName=="InvestExperienceHedgeFund");
                    var idx_SP = data.InvestmentExperienceTypes.findIndex(w=>w.PropName=="InvestExperienceStructural");
                    
                    data.InvestmentExperienceTypes[idx_HFund].Year = 
                    data.InvestmentExperienceTypes[idx_SP].Year =
                    data.InvestmentExperienceTypes[idx_MFund].Year;
                    

                    AOSServices.SaveFinancialInfo(userRef.RefCode, data, "SelfDisclosure")
                        .then(res => {
                            if (!res || !res.Result) {
                                alert("提交資料失敗，請重新再試");
                            }
                            resolve(res.Result);
                        });
                });
            }
        }
    };

    const HasDerivativeTradeExp = () => {
        return watchAllFields.InvestmentExperienceTypes &&
            watchAllFields.InvestmentExperienceTypes.some(w => w.Year !== 0 && w.Type === "D");
    };

    const ExperienceOptionList = ({ text, PIndex, className = "col-sm-6 col-xs-12", item, ...props }) => {
        return (
            ( 
                ["InvestExperienceStock", "InvestExperienceWarrant", "InvestExperienceFutures", "InvestExperienceMutualFund"].includes(item.PropName) && 
            <div className={className}>
                <div className="input-group mb-3">
                    <div className="input-group">
                        <label className="col-8 input-group-text">{text}</label>
                        <select
                            className="form-select"
                            {...register(`InvestmentExperienceTypes.${PIndex}.Year`, { valueAsNumber: true })}
                        >
                            <option value="0"> 没有經驗 </option>
                            <option value="1"> 少於1年 </option>
                            <option value="2"> 2-5年 </option>
                            <option value="6"> 6-10年 </option>
                            <option value="10"> 10年以上 </option>
                        </select>
                    </div>

                </div>
            </div>
            )
        );
    };

    const FBCheckBox = ({ register, children, className = "col-sm-3 col-xs-12", ...props }) => {
        let id = register.name;
        return (
            <ButtonWrapper Lite className={className}>
                <ButtonCheckbox Lite id={id}
                    type="checkbox"
                    {...register}
                ></ButtonCheckbox>
                <ButtonLabel Lite htmlFor={id}>
                    {children}
                    <span className="checkmark"></span>
                </ButtonLabel>
            </ButtonWrapper>
        );
    }

    const cssFormLabelCol = "col-sm-3 col-xs-12 col-form-label my-0 my-md-2";
    const cssFormInputCol = "col-sm-9 col-xs-12 my-0 my-md-2";

    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            {...props}>

            {/* <PrettyPrintJson data={watchAllFields} /> */}

            <form className="ffg-form">
                <div className="row text-start">
                    <div className="col-12">
                        <h3 className="pt-3"> 財政狀況 </h3>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="收入來源:" required={1} />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <FBCheckBox className="col-sm-3 col-xs-6" register={register("FundSourceSalary")}>薪金</FBCheckBox>
                            <FBCheckBox className="col-sm-3 col-xs-6" register={register("FundSourceInvestment")}> 投資收入 </FBCheckBox>
                            <FBCheckBox className="col-sm-3 col-xs-6" register={register("FundSourceFamily")}> 家庭收入 </FBCheckBox>
                            <FBCheckBox className="col-sm-3 col-xs-6" register={register("FundSourceRetire")}> 退休金 </FBCheckBox>
                            <FBCheckBox className="col-sm-3 col-xs-6" register={register("FundSourceProfit")}> 營業收入 </FBCheckBox>
                            <FBCheckBox className="col-sm-3 col-xs-6" register={register("FundSourceSaving")}> 個人儲蓄 </FBCheckBox>
                            {/* <FBCheckBox className="col-sm-3 col-xs-6" register={register("FundSourceOther")}> 其他 </FBCheckBox> */}

                            <span className={errors?.FundSource ? "is-invalid" : ""}></span>
                            <div className="invalid-feedback"> {errors?.FundSource?.message} </div>

                            {watchAllFields.FundSourceOther && (<div>
                                <span className="fb-red subscript">請註明</span>
                                <input
                                    {...register("FundSourceOtherDesc")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.FundSourceOtherDesc)}`}
                                />
                                <div className="invalid-feedback"> {errors?.FundSourceOtherDesc?.message} </div>
                            </div>)}
                        </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="每年收入(港元):" required={1} />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="Income"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={value}
                                            className="col-sm-3 col-xs-12"
                                            Items={[
                                                { name: "2", value: "2", label: "< 360,000" },
                                                { name: "3", value: "3", label: " 360,001 - 600,000 " },
                                                { name: "4", value: "4", label: " 600,001 - 1,200,000 " },
                                                { name: "5", value: "5", label: " > 1,200,000 " },
                                            ]}
                                            name={name} />);
                                }}
                            />
                        </div>

                        <span className={errors?.Income ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.Income?.message} </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="資產分類:" required={1} />
                    <div className={cssFormInputCol}>
                        <FBCheckBox register={register("AssetProperty")}> 固定資產 </FBCheckBox>
                        <FBCheckBox register={register("AssetCash")}> 存款 </FBCheckBox>
                        <FBCheckBox register={register("AssetSecurities")}> 投資 </FBCheckBox>

                        {/* <FBCheckBox register={register("AssetOther")}> 其他 </FBCheckBox> */}

                        <span className={errors?.Asset ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.Asset?.message} </div>

                        {watchAllFields.AssetOther && (<div>
                            <span className="fb-red subscript">請註明</span>
                            <input
                                {...register("AssetOtherDesc")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.AssetOtherDesc)}`}
                            />
                            <div className="invalid-feedback"> {errors?.AssetOtherDesc?.message} </div>
                        </div>)}
                    </div>

                    <FBLabel className={cssFormLabelCol} text="總資產淨值(港元):" required={1} />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="NAV"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={watchAllFields.NAV}
                                            className="col-sm-3 col-xs-12"
                                            Items={[
                                                { name: "3", value: "3", label: "< 500,000" },
                                                { name: "4", value: "4", label: " 500,001 - 1,000,000 " },
                                                { name: "5", value: "5", label: " 1,000,001 - 5,000,000 " },
                                                { name: "6", value: "6", label: " > 5,000,000 " },
                                            ]}
                                            name={name} />);
                                }}
                            />
                        </div>

                        <span className={errors?.NAV ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.NAV?.message} </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="預期每年交易金額(港元):" required={1} />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="Turnover"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={watchAllFields.Turnover}
                                            className="col-sm-4 col-xs-12"
                                            Items={[
                                                { name: "1", value: "1", label: "< 500,000" },
                                                { name: "2", value: "2", label: " 500,001 - 5,000,000 " },
                                                { name: "3", value: "3", label: " > 5,000,000 " },
                                            ]}
                                            name={name} />);
                                }}
                            />
                        </div>
                        <span className={errors?.Turnover ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.Turnover?.message} </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="預期每年交易次數:" required={1} />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="NoOfTrade"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={watchAllFields.NoOfTrade}
                                            className="col-sm-4 col-xs-12"
                                            Items={[
                                                { name: "1", value: "1", label: " < 50 " },
                                                { name: "2", value: "2", label: " 50 - 500 " },
                                                { name: "3", value: "3", label: " > 500 " },
                                            ]}
                                            name={name} />);
                                }}
                            />
                        </div>
                        <span className={errors?.NoOfTrade ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.NoOfTrade?.message} </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <hr className="dot-line" />
                    </div>
                </div>

                <div className="row text-start">
                    <div className="col-12">
                        <h3 className="pt-3"> 投資經驗及衍生產品認識 </h3>
                    </div>



                    <FBLabel className={cssFormLabelCol} text="(i)投資目標" required={1} />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="InvestGoal"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={watchAllFields.InvestGoal}
                                            className="col-sm-3 col-xs-6"
                                            Items={[
                                                { name: "1", value: "1", label: " 股息收入 " },
                                                { name: "2", value: "2", label: " 資本增值 " },
                                                { name: "3", value: "3", label: " 投機 " },
                                                { name: "4", value: "4", label: " 對沖 " },
                                            ]}
                                            name={name} />);
                                }}
                            />
                        </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="(ii)投資經驗" required={1} />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            {/* <Controller
                                name="HasInvestExperience"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            setValueAs={(v) => v === 'true'}
                                            defaultValue={watchAllFields.HasInvestExperience ? 'true' : 'false'}
                                            className="col-sm-6 col-xs-6"
                                            Items={[
                                                { name: "false", value: false, label: "没有" },
                                                { name: "true", value: true, label: "有" },
                                            ]}
                                            name={name} />);
                                }}
                            /> */}
                            <span className="fb-red">
                                如閣下没有衍生工具交易經驗，有關產品會被禁止買賣(包括但不限於︰牛熊證﹑衍生認股證﹑期貨及期權﹑對沖基金﹑結構性產品等)。
                            </span>
                        </div>
                    </div>

                    
                            {
                                InvestExpTypes.map((item, idx) => {
                                    return (<ExperienceOptionList key={idx}
                                        PIndex={idx}
                                        text={item.FullName} item={item}
                                    />)
                                })
                            }

                            <div className="text-center">
                                <span className={errors?.InvestmentExperienceTypes ? "is-invalid" : ""}></span>
                                <div className="invalid-feedback"> {errors?.InvestmentExperienceTypes?.message} </div>
                            </div>

                            {HasDerivativeTradeExp() && (
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body bg-warning bg-opacity-10">
                                            <ButtonWrapper Lite className="col-12">
                                                <ButtonCheckbox Lite id="DerivativeTrade"
                                                    type="checkbox"
                                                    {...register("DerivativeTrade")}
                                                ></ButtonCheckbox>
                                                <ButtonLabel Lite htmlFor="DerivativeTrade" className="label-narrow">
                                                    你於過去3年執行過5次或以上有關衍生產品的交易。<br />
                                                    (如"是"，請於空格 □ 內加上 √ 號，如"否"，不需要鈎選任何選項)
                                                    <span className="checkmark"></span>
                                                </ButtonLabel>
                                            </ButtonWrapper>
                                        </div>
                                    </div>

                                    {/* 
                                    <FBLabel className="col-12 col-form-label my-0 my-md-2">
                                        (iii)客戶對衍生工具的認識評估 (請回答所有問題。如"是"，請於空格 □ 內加上 √ 號，如"否"，不需要鈎選任何選項)
                                    </FBLabel>

                                    <SwitchBoxWapper>
                                        <SwitchBox id="DerivativeCourse" type="checkbox" {...register("DerivativeCourse")} />
                                        <SwitchBoxLabel htmlFor="DerivativeCourse" className="label-narrow">
                                            1. 你已接受任何有關介紹一般衍生產品之性質及風險的培訓或課程 (例如由學術機構或金融機構所提供之網上課程或教室課程)?
                                            <span className="checkmark"></span>
                                        </SwitchBoxLabel>
                                    </SwitchBoxWapper>
                                    {watchAllFields.DerivativeCourse && (
                                        <div className="row card-body bg-danger bg-opacity-10">
                                            <div className="col-sm-4 col-xs-12">
                                                <FBLabel text="課程/研討會名稱:" required={1} />
                                                <input
                                                    {...register("CourseName")}
                                                    type="text"
                                                    className={`form-control ${InvalidFieldStyle(errors?.CourseName)}`}
                                                />
                                                <div className="invalid-feedback"> {errors?.CourseName?.message} </div>
                                            </div>
                                            <div className="col-sm-4 col-xs-12">
                                                <FBLabel text="主辦機構:" required={1} />
                                                <input
                                                    {...register("CourseTrainer")}
                                                    type="text"
                                                    className={`form-control ${InvalidFieldStyle(errors?.CourseTrainer)}`}
                                                />
                                                <div className="invalid-feedback"> {errors?.CourseTrainer?.message} </div>
                                            </div>
                                            <div className="col-sm-4 col-xs-12">
                                                <FBLabel text="參與年份:" required={1} />
                                                <input
                                                    {...register("CourseYear")}
                                                    type="text"
                                                    className={`form-control ${InvalidFieldStyle(errors?.CourseYear)}`}
                                                />
                                                <div className="invalid-feedback"> {errors?.CourseYear?.message} </div>
                                            </div>
                                        </div>
                                    )}

                                    <SwitchBoxWapper>
                                        <SwitchBox id="DerivativeWorkingExp" type="checkbox" {...register("DerivativeWorkingExp")} />
                                        <SwitchBoxLabel htmlFor="DerivativeWorkingExp" className="label-narrow">
                                            2. 你現時或過去是否擁有與衍生產品有關的工作經驗?
                                            <span className="checkmark"></span>
                                        </SwitchBoxLabel>
                                    </SwitchBoxWapper>
                                    {watchAllFields.DerivativeWorkingExp && (
                                        <div className="row card-body bg-danger bg-opacity-10">
                                            <div className="col-sm-6 col-xs-12">
                                                <FBLabel text="任職公司名稱:" required={1} />
                                                <input
                                                    {...register("DerivativeWorkCompany")}
                                                    type="text"
                                                    className={`form-control ${InvalidFieldStyle(errors?.DerivativeWorkCompany)}`}
                                                />
                                                <div className="invalid-feedback"> {errors?.DerivativeWorkCompany?.message} </div>
                                            </div>
                                            <div className="col-sm-6 col-xs-12">
                                                <FBLabel text="職位:" required={1} />
                                                <input
                                                    {...register("DerivativeWorkPosition")}
                                                    type="text"
                                                    className={`form-control ${InvalidFieldStyle(errors?.DerivativeWorkPosition)}`}
                                                />
                                                <div className="invalid-feedback"> {errors?.DerivativeWorkPosition?.message} </div>
                                            </div>
                                        </div>
                                    )} 
                                    */}

                                </div>
                            )}

                </div>

                {(!watchAllFields.DerivativeTrade || !HasDerivativeTradeExp()) && (
                    <div className="row text-start">
                        <div className="col-12">
                            <h3 className="pt-3"> 衍生工具產品交易申請表 </h3>
                        </div>

                        <FBLabel className={cssFormLabelCol} text="閣下有沒有興趣買賣衍生工具產品？" required={1} />
                        <div className={cssFormInputCol}>
                            <div className={`row px-3 px-sm-0`}>
                                <Controller
                                    name="IsInterestedInDerivativeProducts"
                                    control={control}
                                    render={({
                                        field: { onChange, name, value }
                                    }) => {
                                        return (
                                            <RadioButtonList
                                                onChange={onChange}
                                                setValueAs={(v) => v === 'true'}
                                                defaultValue={watchAllFields.IsInterestedInDerivativeProducts ? 'true' : 'false'}
                                                className="col-sm-6 col-xs-12"
                                                Items={[
                                                    { name: "true", value: true, label: "有" },
                                                    { name: "false", value: false, label: "没有" },
                                                ]}
                                                name={name} />);
                                    }}
                                />
                            </div>

                            {!watchAllFields.IsInterestedInDerivativeProducts && (
                                <span className="text-danger">
                                    *如閣下没有意向買賣衍生工具產品，將不能交易以下產品(包括但不限於︰牛熊證﹑衍生認股證﹑期貨及期權﹑對沖基金﹑結構性產品等)
                                </span>
                            )}
                        </div>
                        
                        {errors.IsInterestedInDerivativeProducts && (
                            <div className="card p-3">
                                <p className="text-danger">{errors.IsInterestedInDerivativeProducts.message}</p>
                            </div>
                        )}

                        {watchAllFields.IsInterestedInDerivativeProducts && (
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body bg-danger bg-opacity-10">

                                        閣下有意買賣衍生工具產品的類別
                                        <ul>
                                            <li>
                                                衍生權證及界內證、牛熊證、槓桿及反向產品
                                            </li>
                                            <li>
                                                期貨及期權
                                            </li>
                                            <li>股票期權
                                            </li>
                                        </ul>

                                        如閣下有意買賣衍生工具產品，但沒有相關衍生工具知識或交易經驗，請仔細閱讀以下相關衍生工具產品的風險披露聲明。
                                        <ul>
                                            <li>
                                                <a href="https://media.ffg.com.hk/MediaFolder/MediaPage/CashClientAgreement.pdf" target="_blank">
                                                    “現金客戶協議書”
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://media.ffg.com.hk/MediaFolder/MediaPage/MarginClientAgreement.pdf" target="_blank">
                                                    “保證金客戶協議書”
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://media.ffg.com.hk/MediaFolder/MediaPage//F14.pdf" target="_blank">
                                                    “期貨客戶協議書”
                                                </a>
                                            </li>
                                        </ul>



                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                )}

            </form>

        </StepContainer>
    );
};

export default FinancialInfo;