import axios from "axios";
import * as Sentry from "@sentry/react";

const baseURL = process.env.NODE_ENV === 'development' ? "http://localhost:19146/api" : "/api";

const ApiService = axios.create({
    //baseURL: "http://localhost:19146/api",
    baseURL:baseURL,
    withCredentials: true,
    headers: {
        "Content-type": "application/json"
    }
});

ApiService.interceptors.response.use(
    res => res.data,
    err => {
        Sentry.captureException({
            errorName: 'API error',
            message: err,
        });
        console.log(err, "http-error");
        if (err.response && err.response.status === 401) { 
            //console.log("session timeout!!");
            alert("登入時間已超過 60 分鐘，請重新登入。");
            window.location.href = "/";
        }
    }
);


export default ApiService;
