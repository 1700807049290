import React, { useState, useEffect, useCallback } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import IDCardUploader from '../IDCardUploader/IDCardUploader';
import AOSServices from "../../services/AOSServices";
import { FBLabel, InvalidFieldStyle, PrettyPrintJson } from '../../services/Utils';
import DocUploader from '../DocUploader/DocUploader';

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { subMonths, format } from 'date-fns';
import zh from 'date-fns/locale/zh-CN';
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./PersonalInfo.css";


export default function PersonalInfo(props) {

    // for date picker
    registerLocale('zh', zh);

    const { userRef } = useUserSettings();
    const [IDCardReady, setIDCardReady] = useState(false);
    const [RevisedInfo, setRevisedInfo] = useState(false);

    const validationSchema = Yup.object().shape({
        OCROrderNo: Yup.string().nullable(),
        ChnName: Yup.string().nullable().required("請輸入你的中文姓名"),
        EngName: Yup.string().nullable().required("請輸入你的姓名拼音"),
        HKID: Yup.string().nullable().required("請輸入你的證件號碼"),
        Birthday: Yup.date().nullable().required("請輸入你的出生日期"),
        Gender: Yup.string().nullable().required("請選擇你的稱呼"),
        IsSameAddress: Yup.boolean().nullable(),
        ResidentAddress1: Yup.string().nullable().required("請輸入你的住宅地址"),
        CommunicateAddress1: Yup.string().nullable()
            .when('IsSameAddress', (IsSameAddress, schema) => {
                return !IsSameAddress ? schema.required("請輸入你的通訊地址") : schema.notRequired();
            }),
        Revised: Yup.boolean(),
        Region: Yup.string().nullable(),
    });

    const { register, trigger, watch, setValue, getValues, reset, control, formState: { isValid, errors }, clearErrors } = useForm({
        mode: "all",
        shouldFocusError: true,
        resolver: yupResolver(validationSchema),
        defaultValues: {
            IsSameAddress: true,
            Revised: (userRef.IDCardOrigin === "OTHER" ? true : false)
        }
    });

    const watchAllFields = watch();

    const IDCardReadyhandler = useCallback((val) => {
        if (val) {
            AOSServices.GetInfo(userRef.RefCode, userRef.IDCardOrigin)
                .then((resp) => {
                    //console.log(`resp:` + resp);
                    if (resp && resp.Result) {
                        //console.log(`restore info`);
                        //if (resp.Info.HKID != null && resp.Info.ChnName != null) {
                            reset({

                                Birthday: new Date(resp.Info.Birthday),
                                ChnName: resp.Info.ChnName,
                                CommunicateAddress1: resp.Info.CommunicateAddress1,
                                CommunicateAddress2: resp.Info.CommunicateAddress2,
                                CommunicateAddress3: resp.Info.CommunicateAddress3,
                                EngName: resp.Info.EngName,
                                Gender: resp.Info.Gender,
                                HKID: resp.Info.HKID,
                                IsSameAddress: resp.Info.IsSameAddress,
                                OCROrderNo: resp.Info.OCROrderNo,
                                RefID: resp.Info.RefID,
                                Region: resp.Info.Region,
                                ResidentAddress1: resp.Info.ResidentAddress1,
                                ResidentAddress2: resp.Info.ResidentAddress2,
                                ResidentAddress3: resp.Info.ResidentAddress3,
                                Revised: resp.Info.Revised,
                            });
                        //}

                        setRevisedInfo((userRef.IDCardOrigin === "OTHER") ? true : resp.Info.Revised);
                        //setRevisedInfo(resp.Info.Revised);
                    }
                }).finally(() => setIDCardReady(val));
        } else {
            setIDCardReady(val);
        }
    }, []);



    //const manualAdj = watchAllFields.PInfo.Revised;
    const EnableManualAdjust = () => {
        setValue("Revised", true);
        setRevisedInfo(true);
    };

    const goNext = () => {
        if (!isValid) {
            trigger(undefined, { shouldFocus: true });
            return new Promise(resolve => resolve(false));
        } else {
            const { Birthday, ...PData } = getValues();
            return new Promise(resolve => {
                AOSServices.SavePersonalInfo(userRef.RefCode, {
                    "Birthday": format(watchAllFields.Birthday, "yyyy-MM-dd"), ...PData
                }, "SettlementInfo")
                    .then(res => {
                        if (!res || !res.Result) {
                            alert("提交資料失敗，請重新再試")
                        }
                        resolve(res.Result);
                    });
            });
        }
    };



    // useEffect(() => {
    //     // load initial data
    // })


    const ManualAdjustButton = () => {
        return (userRef.IDCardOrigin !== "OTHER") && !watchAllFields.Revised && (<div className="col-sm-12 col-xs-12 my-0 my-md-2">
            <p>
                如上述個人信息不正確或地址與身份證不同，請 <a href="#_" onClick={EnableManualAdjust}> 按此修改 </a>
            </p>
        </div>);
    };

    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            {...props}>

            {/* <PrettyPrintJson data={watchAllFields} />  */}

            <IDCardUploader onIDCardReady={IDCardReadyhandler}
                UserRef={userRef}
            />

            {IDCardReady && (
                <form className="ffg-form">
                    <div className="row">
                        <div className="col-12">
                            <hr className="dot-line" />
                        </div>

                        <div className="col-12">
                            {/* <span className="text-danger">溫馨提示 : 以下的開戶手續，請客戶細閱條款及填寫資料，部分題目的預設答案，如非客戶所意願的，請再自行選擇，敬請留意。</span> */}
                            <div className="card text-danger bg-light">
                            <div className="card-body text-start">
                                溫馨提示 : 以下的開戶手續，請客戶細閱條款及填寫資料，部分題目的預設答案，如非客戶本人所意願的，請再自行選擇。附有「*」符號的題目，客戶亦必須回答，敬請留意。
                            </div>
                        </div>
                        </div>

                        <div className="col-12 mb-3">
                            <h3 className="pt-3 fb-red"> 個人資料 </h3>
                        </div>

                        <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 中文姓名: </label>
                        <div className="col-sm-9 col-xs-12 my-0 my-md-2">
                            <input {...register("ChnName")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.ChnName)}`}
                                disabled={!RevisedInfo}
                            />
                            {(errors.PInfo && errors.ChnName) &&
                                (<div className="invalid-feedback">
                                    {errors.ChnName.message}
                                </div>)
                            }
                        </div>

                        <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 姓名拼音: </label>
                        <div className="col-sm-9 col-xs-12 my-0 my-md-2">
                            <input {...register("EngName")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.EngName)}`}
                                disabled={!RevisedInfo}
                            />
                            {(errors && errors.EngName) &&
                                (<div className="invalid-feedback">
                                    {errors.EngName.message}
                                </div>)
                            }
                        </div>

                        <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 稱呼: </label>
                        <div className="col-sm-9 col-xs-12 my-0 my-md-2" style={{ textAlign: 'left' }}>
                            <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" value="M"
                                    {...register("Gender")}
                                    disabled={!RevisedInfo}
                                />
                                <label className="btn btn-outline-primary px-5" htmlFor="btnradio1"> 先生 </label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" value="F"
                                    {...register("Gender")}
                                    disabled={!RevisedInfo}
                                />
                                <label className="btn btn-outline-primary px-5" htmlFor="btnradio2"> 女士 </label>
                            </div>
                        </div>

                        <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 證件號碼: </label>
                        <div className="col-sm-9 col-xs-12 my-0 my-md-2">
                            <input  {...register("HKID")}
                                type="text"
                                className={`form-control ${(errors && errors.HKID) ? 'is-invalid' : ""}`}
                                disabled={!RevisedInfo}
                            />
                            {(errors && errors.HKID) &&
                                (<div className="invalid-feedback">
                                    {errors.HKID.message}
                                </div>)
                            }
                        </div>

                        <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 出生日期: </label>
                        <div className="col-sm-9 col-xs-12 my-0 my-md-2">
                            <Controller
                                name={"Birthday"}
                                control={control}
                                render={({ field }) => (
                                    <ReactDatePicker
                                        className={`form-control ${InvalidFieldStyle(errors?.Birthday)}`}
                                        placeholderText=""
                                        dateFormat="yyyy-MM-dd"
                                        locale="zh"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={60}
                                        withPortal
                                        maxDate={subMonths(new Date(), 18 * 12)}
                                        onChange={(e) => field.onChange(e)}
                                        selected={field.value}
                                        disabled={!RevisedInfo}
                                    />
                                )}
                            />
                            {(errors && errors.Birthday) &&
                                (<div className="invalid-feedback">
                                    {errors.Birthday.message}
                                </div>)
                            }
                        </div>

                        <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 住宅地址: </label>
                        <div className="col-sm-9 col-xs-12 my-0 my-md-2">
                            <input
                                {...register("ResidentAddress1")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.ResidentAddress1)}`}
                                disabled={userRef.IDCardOrigin === 'CHN' && !RevisedInfo}
                            />
                            {(errors && errors.ResidentAddress1) &&
                                (<div className="invalid-feedback">
                                    {errors.ResidentAddress1?.message}
                                </div>)
                            }

                            <div className="form-check text-danger text-start">
                                <input
                                    {...register("IsSameAddress", {
                                        onChange: (e) => e.target.checked ? clearErrors("CommunicateAddress1") : null
                                    })}
                                    type="checkbox"
                                    value=""
                                    id="cbIsSameAddress"
                                    className="form-check-input p-3"
                                />
                                <label className="form-check-label" htmlFor="cbIsSameAddress" style={{ marginTop: 7, padding: "3px 10px" }}>
                                    (通訊地址與住宅地址相同)
                                </label>
                            </div>
                        </div>

                        {!watchAllFields.IsSameAddress && (
                            <>
                                <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 通訊地址: </label>
                                <div className="col-sm-9 col-xs-12 my-0 my-md-2">
                                    <input
                                        {...register("CommunicateAddress1")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.CommunicateAddress1)}`}
                                        disabled={watchAllFields.IsSameAddress}
                                    />
                                    {(errors && errors.CommunicateAddress1) &&
                                        (<div className="invalid-feedback">
                                            {errors.CommunicateAddress1.message}
                                        </div>)
                                    }
                                </div>
                            </>
                        )}

                        <ManualAdjustButton></ManualAdjustButton>

                        <label className="col-sm-3 col-xs-12 col-form-label my-0 my-md-2"> 地址證明: </label>
                        <div className="col-sm-9 col-xs-12 my-0 my-md-2 text-start">
                            {/* <span className="d-block py-0 py-md-3">請提供包含姓名及詳細地址的近三個銀行月結單、水電費賬單等。</span> */}
                            <span className="d-block py-0 py-md-3">
                            請提供近三個月內附有閣下姓名及詳細地址的水/電費、銀行月結單 (閣下亦可稍後於本程式內上載相關文件)
                            </span> 
                            


                            <DocUploader AType="AddressProof" RefCode={userRef.RefCode}></DocUploader>

                        </div>

                        
                    </div>
                </form>
            )}

            <div className="col-12"></div>



        </StepContainer>
    );
}