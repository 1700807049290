import styled, { css } from 'styled-components'

export const ButtonWrapper = styled.div`
display: inline-flex;
position: relative;
padding: 4px;
margin: 0px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

.checkmark {
    position: absolute;
    top: 33%;
    left: 20px;
    height: 30px;
    width: 30px;
    background-color: #ced4da;
    border-radius: 50%;
}

& input:checked ~ label > .checkmark:after {
    display: block;
}

& input:checked ~ label > .checkmark {
    background-color: rgb(204,0,16);
}

&:hover input:enabled ~ label {
    color: rgb(204,0,16);
    border: 1px solid rgb(204,0,16);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 10px;
    top: 0px;
    width: 10px;
    height: 25px;
    border: solid rgb(255,248,31);
    border-width: 0px 5px 7px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  ${props =>
        props.Lite &&
        css`
        .checkmark {
            top: 28%;
            left: 20px;
            height: 30px;
            width: 30px;
            background-color: #ced4da;
            border-radius: 4px;
        }

        & input:checked ~ label > .checkmark {
        
        }

        &:hover input:enabled ~ label {
            color: rgb(204,0,16);
            border: 0px;
        }        

        .checkmark:after {
            border: solid rgb(255,255,255);
            border-width: 0px 5px 7px 0px;
        }
    `};
`;

export const ButtonLabel = styled.label`
display: inline-grid;
width: 100%;
cursor: pointer;
padding: 16px !important;
padding-left: 55px !important;
border: 1px solid #ced4da;
border-radius: 35px;
background-color: rgb(255,248,252);

${props =>
        props.Lite &&
        css`
        border: 0px;
        background-color: transparent;
    `};
`;

export const ButtonCheckbox = styled.input`
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;

&:checked ~ label {
    color: rgb(204,0,16);
    border: 1px solid rgb(204,0,16);
}

&:disabled ~ label {
    color: rgb(160,160,160);
    cursor: not-allowed;
}

${props =>
        props.Lite &&
        css`
        &:checked ~ label {
            border: 0px;
        }
    `};
`;

