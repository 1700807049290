import React from "react";
import "./promotion.css";

export const Rebate_9_3 = (props) => {
    
    return (
        <div  className="pro-bg">
            <div className="col-12">
                <h3 className="pt-3 pro-header"> 富昌證券：「港股9個月（先收後回）免佣優惠」 </h3>
            </div>

            <div className="col-11 offset-1">
                <p>
                    新客戶於富昌證券有限公司（下文簡稱「本公司」）成功開立證券戶口及在首9個月內買賣港股，即可享受「港股9 個月（先收後回）免佣優惠」（下文簡稱「本優惠」）。新客戶在「本優惠」計劃「免佣期」內支付的交易佣金，於「回贈期」內只要滿足所有相關條款及細則便獲可得回贈，詳情如下：
                </p>

                <p><b>條款及細則：</b></p>
                <ol>
                    <li>
                        本優惠的「免佣期」是指新客戶在開立證券帳戶後的首9 個月，本公司會以「先收後回」方式，先收取客戶於電子渠道交易港股產生的總佣金（下文簡稱「該佣金），然後將其平均分開三期安排回贈。
                    </li>
                    <li>
                        本優惠的「回贈期」是指新客戶在開立證券帳戶後的第10 至第12 個月，每月可獲得一期等值於該佣金平均數的「回贈額」。
                    </li>
                    <li>
                        客戶於當月「回贈期」內，凡透過電子渠道交易港股產生的佣金均可獲得回贈，回贈上限為當月「回贈額」。
                    </li>
                    <li>
                        客戶在當月的「回贈額」如尚有餘數，該餘數會被取消，不可延至下一個月繼續使用。
                    </li>
                    <li>
                        所有上述之優惠不可兌換成現金或其他貨品、服務及優惠。
                    </li>
                    <li>
                        客戶當月「回贈期」應獲得的回贈佣金，將於下一月中旬存入客戶的相關證券帳戶內。
                    </li>
                    <li>
                        「佣金」並不包括：印花稅、證監會交易徵費、聯交所交易費、香港結算公司結算費、財務匯報局交易徵費、交易稅及其他所有雜費。
                    </li>
                    <li>
                        於本優惠期內，如客戶的交易帳戶因客戶自行終止，或被本公司因客戶的不恰當行為（包括但不限於對本公司的欺詐、誣捏、惡意中傷等）而終止，期間客戶應收的回贈佣金將不會獲得退回。
                    </li>
                    <li>
                        本公司保留隨時更改本優惠條款及細則的權利。本公司亦可能運用酌情權取消此優惠而毋須事先通知。
                    </li>
                    <li>
                        如有任何爭議，本公司保留最終決定權。
                    </li>
                    <li>
                        本優惠並非及不應被視為進行任何投資的邀請、要約或投資建議。客戶應注意，投資涉及風險，投資產品價格可升可跌，投資前請充分理解產品風險，並應就此諮詢專業顧問。
                    </li>
                    <li>
                        本優惠適用於新客戶開立的個人及聯名證券交易帳戶(包括現金及保證金證券帳戶)。
                    </li>
                </ol>

                <b>例子如下：</b>

                <table className="pro-sample-table" cellPadding={1} cellSpacing={0} border={2}>
                    <thead>
                        <tr>
                            <th>
                                「港股9 個月（先收後回）免佣優惠」的「免佣期」
                            </th>
                            <th>
                                「免佣期」期間，客戶於電子渠道交易港股產生的總佣金
                            </th>
                            <th>
                                每月回贈佣金金額
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                假設9 個月的「免佣期」：<br />
                                由2023 年1 月1 日開始 至2023年9 月30 日止
                            </td>
                            <td>
                                合共HK$9,000
                            </td>
                            <td>
                                HK$9,000 ÷ 3 = HK$3,000<br /><br />
                                客戶於第10 至第12 個月的三個月內，每月可獲得HK$3,000 回贈額，此額度不可兌換成現金或其他貨品、服務及優惠，只僅限於抵銷港股交易佣金之用
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <table className="pro-sample-table" cellPadding={1} cellSpacing={0} border={2}>
                    <thead>
                        <tr>
                            <th>
                                回贈月份
                            </th>
                            <th>
                                每月回贈額
                            </th>
                            <th>
                                當月客戶於電子渠道交易港股產生的佣金
                            </th>
                            <th>
                                回贈佣金金額
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                2023 年10 月
                            </td>
                            <td>
                                HK$3,000
                            </td>
                            <td>
                                HK$5,000
                            </td>
                            <td>
                                HK$3,000<br />
                                （回贈上限為「回贈額」）
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2023 年11 月
                            </td>
                            <td>
                                HK$3,000
                            </td>
                            <td>
                                HK$1,000
                            </td>
                            <td>
                                HK$1,000<br />
                                （「回贈額」餘數(HK$2,000) 被取消）
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2023 年12 月
                            </td>
                            <td>
                                HK$3,000
                            </td>
                            <td>
                                HK$0
                            </td>
                            <td>
                                HK$0<br />
                                （「回贈額」餘數(HK$3,000)全數被取消）
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
