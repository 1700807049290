import React, { useState, useEffect } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import { FBLabel, InvalidFieldStyle, PrettyPrintJson, cssFormInput } from '../../services/Utils';
import { RadioButtonList } from "../FFGCheckbox/FFGCheckbox";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select, { components, createFilter } from 'react-select';

const EmploymentInfo = (props) => {
    const { userRef } = useUserSettings();
    const [CountryList, setCountryList] = useState([]);
    const [ComNatureList, setComNatureList] = useState([]);
    const [StateList, setStateList] = useState([]);
    const [CityList, setCityList] = useState([]);
    const [WorkPositionList, setWorkPositionList] = useState([]);
    const [DefaultTaxCode, setDefaultTaxCode] = useState("");

    const validationSchema = Yup.object().shape({
        Nationality: Yup.string().nullable().required("請選擇國籍"),
        //IsGreenCardHolder: Yup.string().oneOf(["N", "Y"], "請選擇是否為美國公民/美國居民"),
        IsGreenCardHolder: Yup.string().nullable().required("請選擇是否為美國公民/美國居民"),
        USArea1: Yup.string().nullable().required("請選居留司法管轄區 "),
        HasTaxCode1: Yup.string().nullable().oneOf(["N", "Y"], "請選擇有没有稅務編號"),
        TaxCode1: Yup.string().nullable()
            .when('HasTaxCode1', (HasTaxCode1, schema) => {
                return HasTaxCode1 === "Y" ?
                    schema.required("請輸入稅務編號") : schema.notRequired();
            }),
        NoTaxCodeReason1: Yup.string().nullable()
            .when('HasTaxCode1', (HasTaxCode1, schema) => {
                return HasTaxCode1 === "N" ?
                    schema.required("請選擇沒有稅務編號的理由") : schema.notRequired();
            }),
        OptionBReason1: Yup.string().nullable()
            .when(['NoTaxCodeReason1'], (val, schema) => {
                return val === "B" ?
                    schema.required("請提交解釋帳戶持有人不能取稅務編號的原因") : schema.notRequired();
            }),
        Education: Yup.string().nullable().required("請選擇教育程度"),
        WorkStatus: Yup.string().nullable().required("請選擇工作狀況"),
        // CompanyPhoneCountry: Yup.string().nullable().notRequired(),
        // CompanyPhone: Yup.string().nullable().notRequired(),
        WorkStatusOtherDesc: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return val === "O" ?
                    schema.required("請輸入工作狀況") : schema.notRequired();
            }),
        EmployerName: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return ShowWorkingInfo(val) ?
                    schema.required("請輸入公司名稱") : schema.notRequired();
            }),
        CompanyNatureCate: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return ShowWorkingInfo(val) ?
                    schema.required("請選擇公司行業分類") : schema.notRequired();
            }),
        CompanyNature: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return ShowWorkingInfo(val) ?
                    schema.required("請選擇業務性質") : schema.notRequired();
            }),
        WorkPosition: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return ShowWorkingInfo(val) ?
                    schema.required("請輸入職位") : schema.notRequired();
            }),
        WorkPositionOtherDesc: Yup.string().nullable()
            .when(['WorkPosition'], (val, schema) => {
                return val === "Other" ?
                    schema.required("請註明職位") : schema.notRequired();
            }),
        ServiceYear: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return ShowWorkingInfo(val) ?
                    schema.required("請輸入工作年期") : schema.notRequired();
            }),
        WorkAddressProvince: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return ShowWorkingInfo(val) ?
                    schema.required("請選擇省市") : schema.notRequired();
            }),
        WorkAddressCity: Yup.string().nullable()
            .when(['WorkStatus'], (val, schema) => {
                return ShowWorkingInfo(val) ?
                    schema.required("請選擇城市") : schema.notRequired();
            }),
        WorkAddress: Yup.string().nullable()
        .when(['WorkStatus'], (val, schema) => {
            return ShowWorkingInfo(val) ?
                schema.required("請輸入工作地址") : schema.notRequired();
        }),
    });

    const { register, trigger, watch, getValues, setValue, handleSubmit, reset, control,
        formState: { isValid, errors }, clearErrors } = useForm({
            mode: "all",
            shouldFocusError: true,
            resolver: yupResolver(validationSchema),
            defaultValues: {
                IsGreenCardHolder: "N",
                //HasTaxCode1: "Y",
                //Education: "U",
                //WorkStatus: "E",
            }
        });

    const watchAllFields = watch();

    useEffect(() => {
        AOSServices.GetEmploymentInfo(userRef.RefCode)
            .then((resp) => {
                if (resp && resp.Result) {

                    setDefaultTaxCode(resp.Info.DefaultTaxCode);
                    setCountryList(resp.Info.CountryList);
                    setComNatureList(resp.Info.CompanyNatureList);
                    setStateList(resp.Info.StateList);
                    setCityList(resp.Info.CityList);
                    setWorkPositionList(resp.Info.WorkPositionList);

                    //console.log("reset");
                    reset(resp.Info.Data);
                }
            });
    }, [reset]);

    const HandleTaxCountryChanged = (e) => {
        //console.log(e.target.value);
        if (['CHN', 'HKG', 'MAC'].includes(e.target.value) && !watchAllFields.TaxCode1) {
            setValue("HasTaxCode1", "Y");
            setValue("TaxCode1", DefaultTaxCode);
        }

    };

    const CompanyNatureOptions = 
         ComNatureList.find(w => w.CateName === watchAllFields.CompanyNatureCate)?.Items.map((m, idx) => {
            return (<option key={idx} value={m.Code}> {m.Name} </option>)
        });
    

    const CityOptions = 
         CityList.filter(w => w.Cate === watchAllFields.WorkAddressProvince)?.map((m, idx) => {
            return (<option key={idx} value={m.Code}> {m.Name} </option>)
        });
    

    const goNext = () => {
        if (!isValid) {
            trigger(undefined, { shouldFocus: true });
            return new Promise(resolve => resolve(false));
        } else {
            const data = getValues();
            return new Promise(resolve => {
                AOSServices.SaveEmploymentInfo(userRef.RefCode, data, "FinancialInfo")
                    .then(res => {
                        if (!res || !res.Result) {
                            alert("提交資料失敗，請重新再試");
                        }
                        return resolve(res.Result);
                    });
            });
        }
    };

    const cssFormLabelCol = "col-sm-3 col-xs-12 col-form-label my-0 my-md-2";
    const cssFormInputCol = "col-sm-9 col-xs-12 my-0 my-md-2";

    const ShowWorkingInfo = (WorkStatus) => ['E', 'S', 'Z'].includes(WorkStatus);

    //     const WorkingInfo = () => {
    //         return (
    // <></>
    //         )
    //     };

    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            {...props}>
            <form className="ffg-form">

                <div className="row text-start">
                    <div className="col-12">
                        <h3 className="pt-3"> 教育程度及工作狀況 </h3>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="教育程度:" required={1}></FBLabel>
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="Education"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={value}
                                            className="col-sm-6 col-xs-12"
                                            Items={[
                                                { name: "U", value: "U", label: "大學或以上" },
                                                { name: "S", value: "S", label: "中學" },
                                                // { name: "P", value: "P", label: "小學或以下" },
                                                // { name: "O", value: "O", label: "其他" },
                                            ]}
                                            name="Education" />);
                                }}
                            />
                        </div>
                        <span className={errors?.Education ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.Education?.message} </div>

                        {watchAllFields.Education === 'O' && (<>
                            <span className="fb-red subscript">請註明</span>
                            <input
                                {...register("EducationOther")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.EducationOther)}`}
                            />
                            <div className="invalid-feedback"> {errors?.EducationOther?.message} </div>
                        </>)}
                    </div>

                    <FBLabel className={cssFormLabelCol} text="工作狀況:" required={1}></FBLabel>
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="WorkStatus"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={value}
                                            className="col-sm-4 col-xs-12"
                                            Items={[
                                                { name: "E", value: "E", label: "受僱" },
                                                { name: "S", value: "S", label: "自僱" },
                                                { name: "R", value: "R", label: "退休" },
                                                { name: "H", value: "H", label: "家庭主婦" },
                                                { name: "U", value: "U", label: "學生" },
                                                { name: "Z", value: "Z", label: "待業 (請填上一份工作的資料)" },
                                                // { name: "O", value: "O", label: "其他" },
                                            ]}
                                            name="WorkStatus" />);
                                }}
                            />
                        </div>
                        <span className={errors?.WorkStatus ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.WorkStatus?.message} </div>

                        {watchAllFields.WorkStatus === 'O' && (<>
                            <span className="fb-red subscript">請註明</span>
                            <input
                                {...register("WorkStatusOtherDesc")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.WorkStatusOtherDesc)}`}
                            />
                            <div className="invalid-feedback"> {errors?.WorkStatusOtherDesc?.message} </div>
                        </>)}
                    </div>

                </div>

                {ShowWorkingInfo(watchAllFields.WorkStatus) && (
                    // <WorkingInfo />
                    <div className="row bg-primary bg-opacity-10 mt-3 pb-3">



                        <div className="col-12">
                            <h4 className="pt-2 px-0 fb-red"> 公司資料 </h4>
                        </div>

                        <FBLabel className={cssFormLabelCol} text="公司名稱:" required={1}></FBLabel>
                        <div className={cssFormInputCol}>
                            <input
                                {...register("EmployerName")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.EmployerName)}`}
                            />
                            <div className="invalid-feedback"> {errors?.EmployerName?.message} </div>
                        </div>

                        <FBLabel className={cssFormLabelCol} text="公司行業分類 / 業務性質:" required={1}></FBLabel>
                        <div className="col-sm-4 col-xs-12 my-0 my-md-2">
                            <select
                                {...register("CompanyNatureCate")}
                                className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.CompanyNatureCate)}`}
                            >
                                <option></option>
                                {
                                    ComNatureList && ComNatureList.map((m, idx) => {
                                        return (<option key={idx} value={m.CateName}> {m.CateName} </option>)
                                    })
                                }
                            </select>
                            <div className="invalid-feedback"> {errors?.CompanyNatureCate?.message} </div>
                        </div>

                        {/* <FBLabel className={cssFormLabelCol} text="業務性質:" required={1} /> */}
                        <div className="col-sm-4 offset-sm-1 col-xs-12 my-0 my-md-2">
                            <select
                                {...register("CompanyNature")}
                                className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.CompanyNature)}`}
                            >
                                <option></option>
                                {/* <CompanyNatureOptions CateName={watchAllFields.CompanyNatureCate} /> */}
                                {CompanyNatureOptions}
                            </select>
                            <div className="invalid-feedback"> {errors?.CompanyNature?.message} </div>
                        </div>

                        <FBLabel className={cssFormLabelCol} text="職位:" required={1}></FBLabel>
                        <div className={cssFormInputCol}>
                            <select
                                {...register("WorkPosition")}
                                className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.WorkPosition)}`}
                            >
                                <option></option>
                                {
                                    WorkPositionList && WorkPositionList.map((m, idx) => {
                                        return (<option key={idx} value={m.Code}> {m.Name} </option>)
                                    })
                                }
                            </select>
                            <div className="invalid-feedback"> {errors?.WorkPosition?.message} </div>
                            {watchAllFields.WorkPosition === 'Other' && (<div className="text-start">
                                <span className="fb-red subscript ">請註明</span>
                                <input
                                    {...register("WorkPositionOtherDesc")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.WorkPositionOtherDesc)}`}
                                />
                                <div className="invalid-feedback"> {errors?.WorkPositionOtherDesc?.message} </div>
                            </div>)}
                        </div>

                        <FBLabel className={cssFormLabelCol} text="工作年期:" required={1}></FBLabel>
                        <div className={cssFormInputCol}>
                            <input
                                {...register("ServiceYear")}
                                type="number"
                                className={`form-control ${InvalidFieldStyle(errors?.ServiceYear)}`}
                            />
                            <div className="invalid-feedback"> {errors?.ServiceYear?.message} </div>
                        </div>


                        {/* <div className="col-12">
                        <h5 className="pt-2 px-0 fb-red"> 工作地址 </h5>
                    </div> */}

                        <FBLabel className={cssFormLabelCol} text="工作地址:" required={1}></FBLabel>
                        <div className="col-sm-4 col-xs-12 my-0 my-md-2">
                            <select
                                {...register("WorkAddressProvince")}
                                className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.WorkAddressProvince)}`}
                            >
                                <option></option>
                                {
                                    StateList && StateList.map((m, idx) => {
                                        return (<option key={idx} value={m.Code}> {m.Name} </option>)
                                    })
                                }
                            </select>
                            <div className="invalid-feedback"> {errors?.WorkAddressProvince?.message} </div>
                        </div>

                        <div className="col-sm-4 offset-sm-1 col-xs-12 my-0 my-md-2">
                            <select
                                {...register("WorkAddressCity")}
                                className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.WorkAddressCity)}`}
                            >
                                <option></option>
                                {/* <CityOptions StateCode={watchAllFields.WorkAddressProvince} /> */}
                                {CityOptions}
                            </select>
                            <div className="invalid-feedback"> {errors?.WorkAddressCity?.message} </div>
                        </div>

                        <FBLabel className={cssFormLabelCol} text=""></FBLabel>
                        <div className={cssFormInputCol}>
                            <input
                                {...register("WorkAddress")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.WorkAddress)}`}
                            />
                            <div className="invalid-feedback"> {errors?.WorkAddress?.message} </div>
                        </div>

                        {/* <FBLabel className={cssFormLabelCol} text="公司電話:"></FBLabel>
                        <div className="col-xs-12 col-sm-3 my-0 my-md-10 mb-3">
                            <select
                                {...register("CompanyPhoneCountry")}
                                className={`form-select form-select-lg`}
                            >
                                <option value=""></option>
                                <option value="852"> 中國香港 +852 </option>
                                <option value="86"> 中國內地 +86 </option>
                                <option value="853"> 中國澳門 +853 </option>
                                <option value="886"> 中國台灣 +886 </option>
                            </select>
                        </div>
                        <div className="offset-sm-1 col-xs-12 col-sm-5 my-0 my-md-10">
                            <input
                                {...register("CompanyPhone")}
                                type="text"
                                className={`form-control ${InvalidFieldStyle(errors?.CompanyPhone)}`}
                            />
                            <div className="invalid-feedback"> {errors?.CompanyPhone?.message} </div>
                        </div> */}

                    </div>
                )}

                <div className="row text-start">
                    <div className="col-12">
                        <hr className="dot-line" />
                    </div>

                    <div className="col-12">
                        <h3 className="pt-3"> 國籍及稅務狀況 </h3>
                        <span>
                            (請參閱海外帳戶稅收合規法案聲明書 (個人投資者) 及自動交換財務帳戶資料)
                        </span>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="國籍:" required={true} />
                    <div className={cssFormInputCol}>
                        <select
                            {...register("Nationality")}
                            className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.Nationality)}`}
                        >
                            <option></option>
                            {
                                CountryList && CountryList.map((m, idx) => {
                                    return (<option key={idx} value={m.Code}> {m.Name} </option>)
                                })
                            }
                        </select>
                        <div className="invalid-feedback"> {errors?.Nationality?.message} </div>
                    </div>

                    <FBLabel className={cssFormLabelCol}>
                        海外帳戶稅收合規法案: <br />
                        閣下是否為美國公民/美國居民(包括綠卡持有者)
                    </FBLabel>
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="IsGreenCardHolder"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={watchAllFields.IsGreenCardHolder}
                                            className="col-sm-6 col-xs-12"
                                            Items={[
                                                { name: "N", value: "N", label: "否" },
                                                { name: "Y", value: "Y", label: "是 (本公司不接受此類人士開戶)" },
                                            ]}
                                            name={"IsGreenCardHolder"} />);
                                }}
                            />
                            <div className="invalid-feedback"> {errors?.IsGreenCardHolder?.message} </div>
                        </div>
                    </div>


                    <div className="col-12">
                        <div className="card text-dark bg-light">
                            <div className="card-body">
                                自動交換財務帳戶資料: <br />
                                請提供以下資料，列明帳戶持有人的居留司法管轄區，亦即帳戶持有人的稅務管轄區（香港包括在內）及該居留司法管轄區發給帳戶持有人的稅務編號。居留司法管轄區及稅務編號或具有等同功能的識辨編號（以下簡稱「稅務編號」）*如帳戶持有人是香港稅務居民，稅務編號是其香港身份證號碼。如沒有提供稅務編號，必須填寫合適的理由。
                            </div>
                        </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="居留司法管轄區:" required={true}></FBLabel>
                    <div className={cssFormInputCol}>
                        <select
                            {...register("USArea1", { onChange: HandleTaxCountryChanged })}
                            className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.USArea1)}`}
                        >
                            <option></option>
                            {
                                CountryList && CountryList.map((m, idx) => {
                                    return (<option key={idx} value={m.Code}> {m.Name} </option>)
                                })
                            }
                        </select>
                        <div className="invalid-feedback"> {errors?.USArea1?.message} </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="稅務編號:" required={true}></FBLabel>
                    <div className={cssFormInputCol}>
                        <Controller
                            name="HasTaxCode1"
                            control={control}
                            render={({
                                field: { onChange, name, value }
                            }) => {
                                return (
                                    <RadioButtonList
                                        onChange={onChange}
                                        className="col-sm-6 col-xs-12"
                                        defaultValue={value}
                                        Items={[
                                            { name: "Y", value: "Y", label: "有稅務編號" },
                                            { name: "N", value: "N", label: "没有稅務編號" },
                                        ]}
                                        name="HasTaxCode1" />);
                            }}
                        />
                        <span className={errors?.HasTaxCode1 ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback"> {errors?.HasTaxCode1?.message} </div>

                        {watchAllFields.HasTaxCode1 === "Y" && (
                            <>
                                <span className="fb-blue" style={{ fontSize: "0.8em" }}> (中國內地，香港，澳門稅務編號為身份證號碼)<br />
                                    #如預設或自動填寫的稅務編號不正確，閣下請自行修改。</span>
                                <input
                                    {...register("TaxCode1")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.TaxCode1)}`}
                                />
                                <div className="invalid-feedback"> {errors?.TaxCode1?.message} </div>
                            </>
                        )}
                    </div>

                    {watchAllFields.HasTaxCode1 === "N" && (
                        <>
                            <FBLabel className={cssFormLabelCol}
                                text="如沒有提供稅務編號請填寫理由(A、B或C)" required={true}>
                            </FBLabel>
                            <div className={cssFormInputCol}>
                                <select
                                    {...register("NoTaxCodeReason1")}
                                    className={`form-select form-select-lg mb-3 ${InvalidFieldStyle(errors?.NoTaxCodeReason1)}`}
                                >
                                    <option value="">請選擇</option>
                                    <option value="A"> 理由 A </option>
                                    <option value="B"> 理由 B </option>
                                    <option value="C"> 理由 C </option>
                                </select>
                                <div className="invalid-feedback"> {errors?.NoTaxCodeReason1?.message} </div>

                                <span className="fw-bold">理由 A </span>- 帳戶持有人的居留司法管轄區並沒有向其居民發出稅務編號。<br />
                                <span className="fw-bold">理由 B </span>- 帳戶持有人不能取得稅務編號。如選取這一理由，解釋帳戶持有人不能取得稅務編號的原因。<br />
                                <span className="fw-bold">理由 C </span>- 帳戶持有人毋須提供稅務編號。居留司法管轄區的主管機關不需要帳戶持有人披露稅務編號。<br />

                                {watchAllFields.NoTaxCodeReason1 === "B" && (
                                    <>
                                        <span className="fb-red" style={{ fontSize: "0.8em" }}>
                                            如選取理由B，解釋帳戶持有人不能取稅務編號的原因
                                        </span>
                                        <input
                                            {...register("OptionBReason1")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.OptionBReason1)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.OptionBReason1?.message} </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}


                </div>
            </form>

        </StepContainer>
    );
};

export default EmploymentInfo;