import React from "react";
import "./promotion.css";

export const TOTIELONG_FUT = (props) => {
    
    return (
        <div className="pro-bg">
            <div className="col-12">
                <h3 className="pt-3 pro-header"> 港期真正0佣金優惠 </h3>
            </div>

            <div className="col-11 offset-1">
                <p>
                新客戶於富昌期貨有限公司（下文簡稱「本公司」）成功開立期貨戶口，在首2個月內買賣港期產品 (即: 恒生指數期貨/小型恒生指數期貨/恒生中國企業指數期貨/小型恒生中國企業指數期貨)，當中個別產品的首30張交易佣金，只要符合所有相關條款及細則便獲可得回贈，詳情如下： 
                </p>

                <p><b>條款及細則：</b></p>

                <ol>
                    <li>推廣期 : 即日起至2023年4月30日。 </li>
                    <li>此推廣活動只適用於本公司之新客戶。 </li>
                    <li>新客戶是指 : 在開戶當日前12個月內未曾以公司、個人或聯名方式持有任何本公司的戶口。 </li>
                    <li>本優惠的是指新客戶在開立期貨戶口後的首2個月(開戶月+下一個月)，期間新客戶於電子渠道交易港期產品 (即: 恒生指數期貨/小型恒生指數期貨/恒生中國企業指數期貨/小型恒生中國企業指數期貨)，當中個別產品的首30張交易佣金（下文簡稱「該佣金」），會於第3個月內回贈給新客戶。 </li>
                    <li>新客戶必須以電郵方式接收日/月結單，方可獲得優惠。 </li>
                    <li>「該佣金」並不包括：證監會費用、交易所費用及其他所有雜費。 </li>
                    <li>於本優惠期內，如新客戶的期貨帳戶因新客戶自行終止，或被本公司因新客戶的不恰當行為（包括但不限於對本公司的欺詐、誣捏、惡意中傷等）而終止，期間新客戶應收的該佣金將不會獲得退回。 </li>
                    <li>本優惠適用於新客戶開立的個人及聯名期貨交易戶口。 </li>
                    <li>所有上述之優惠不可兌換成現金或其他貨品、服務及優惠。 </li>
                    <li>本公司保留隨時更改本優惠條款及細則的權利。本行亦可能運用酌情權取消此優惠而毋須事先通知。 </li>
                    <li>如有任何爭議，本公司保留最終決定權。 </li>
                    <li>本優惠並非及不應被視為進行任何投資的邀請、要約或投資建議。客戶應注意，投資涉及風險，投資產品價格可升可跌，投資前請充分理解產品風險，並應就此諮詢專業顧問。</li>
                </ol>
            </div>
        </div>
    );
};
