import React from "react";
import "./promotion.css";

export const SEC_2M_0_Comm = (props) => {
    
    return (
        <div  className="pro-bg">
            <div className="col-12">
                <h3 className="pt-3 pro-header"> 富昌證券：新客戶「 2 個月港股零佣金優惠」 </h3>
            </div>

            <div className="col-11 offset-1">
                <p>
                    <b>
                    新客戶於富昌證券有限公司（下文簡稱「本公司」）成功開立證券交易帳戶，即可享有「2個月港股零佣金優惠」（下文簡稱「本優惠」），詳情如下：
                    </b>
                </p>

                <p>
                    新開立的證券交易帳戶，優惠期由開戶當月及其後2個月，港股網上交易佣金費用全免。優惠期完結後，按現時「一般客戶收費」計算佣金費用*。
                    *「一般客戶收費」 – 會按本公司不時調整的港股網上交易收費作準
                </p>

                <p>
                新客戶如在開戶同時提供其本人於銀行或其他證明行的最近交易單據，可按相關交易單據內的佣金收費以八折計算「最終收費」**** 當以八折計算後的佣金收費是低於本公司的「一般客戶收費」，就以此收費作為「最終收費」; 如否，則按本公司的「一般客戶收費」計算
                </p>

                <p><b>其他條款及細則</b></p>
                <ol>
                    <li>
                    推廣期 : 2023年2月20日起，直至另行通知。
                    </li>
                    <li>
                    此推廣活動只適用於本公司之新客戶。
                    </li>
                    <li>
                    新客戶是指 : 在開戶當日前12個月內未曾以公司、個人或聯名方式持有任何
本公司的戶口。

                    </li>
                    <li>
                    本優惠的是指新客戶在優惠期內，港股網上交易佣金費用全免，但不包括:印
花稅、證監會交易徵費、聯交所交易費、香港結算公司結算費、財務匯報局交易徵費、交易稅及其他所有雜費。

                    </li>
                    <li>
                    新客戶必須以電郵方式接收日/月結單，方可獲得此優惠。
                    </li>
                    <li>
                    本優惠適用於新客戶開立的個人及聯名立證券交易帳戶。
                    </li>
                    <li>
                    所有上述之優惠不可兌換成現金或其他貨品、服務及優惠。
                    </li>
                    <li>
                    本公司保留隨時更改本優惠條款及細則的權利。本行亦可能運用酌情權取消此
優惠而毋須事先通知。

                    </li>
                    <li>
                    如有任何爭議，本公司保留最終決定權。
                    </li>
                    <li>
                    本優惠並非及不應被視為進行任何投資的邀請、要約或投資建議。客戶應注
意，投資涉及風險，投資產品價格可升可跌，投資前請充分理解產品風險，並
應就此諮詢專業顧問。

                    </li>
                </ol>
                
            </div>
        </div>
    );
};
