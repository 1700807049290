import styled, { css } from 'styled-components'

export const PreviewImgWrapper = styled.div`
    margin:0.25rem;
    padding:auto;
    height:80px;
    width:80px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    position: relative;

    & span.Trash {
        position: absolute;
        right: -8px;
        top: -5px;
    }
`;

export const PreviewImg = styled.img`
    padding: 0.25rem;
    max-width: 100%;
    height: auto;
`;

export const DocUploadButton = styled.div`
    display:block;
    height:100%;

    & input {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        font-size: 0;
        opacity: 0;
    }

    ${props =>
        props.disabled &&
        css`
        background-color: #ececec;
    `};
`;