import React from "react";

export const PrettyPrintJson = ({ data }) => {
    return (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>);
};

export const ReqFieldIcon = () => {
    return (<span className="text-danger"> *</span>)
};

export const FBLabel = (props) => {
    return (<label {...props}> {props.text} {props.required && <ReqFieldIcon />} {props.children}</label>)
};

export const PropIsExist = (obj, PropName) => PropName && PropName.split('.').reduce((a, b) => (a || {})[b], obj) !== undefined;


export const cssFormInput = (ErrMsg) => {
    return 'form-control' + (ErrMsg ? ' is-invalid' : '');
};

export const InvalidFieldStyle = (ErrorFieldName) => ErrorFieldName ? "is-invalid" : "";

export const GetIDCardOriginName = (Code) => {
    switch (Code?.toUpperCase() ?? "") {
         case "HKG": return "中國香港身份證";
         case "CHN": return "中國內地身份證";
         case "OTHER": return "其他國家證件";
         default:
           return "";
    }
};
