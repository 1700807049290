import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AOSService from "../../services/AOSServices";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import zh from 'date-fns/locale/zh-CN';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from '../../assets/site_logo.png';

import "./OpeningBooking.css";


export default function OpeningBooking(props) {

    // for date picker
    registerLocale('zh', zh);

    // const { userRef } = useUserSettings();
    // const { WizardInstance, nextStepName } = props;
    const [errorMsg, setErrorMsg] = useState();
    const [IsProcessingNext, setIsProcessingNext] = useState(false);
    const [ isFromSuccess, setIsFromSuccess ] = useState(false);

    const validationSchema = Yup.object().shape({
        ClientName: Yup.string().required("請輸入客戶名稱"),
    });

    useEffect(() => {
        // handle data existed
        AOSService.OpeningBooking("")
            .then(res => {
                if (!res) {
                    setErrorMsg("資料錯誤");
                } else if (!res.Result && res.Existed) {
                    setIsFromSuccess(true);
                }
            });
    }, []);

    const { register, trigger, watch, getValues, setValue, handleSubmit, reset, control,
        formState: { isValid, errors }, clearErrors } = useForm({
            mode: "all",
            shouldFocusError: true,
            resolver: yupResolver(validationSchema),
            defaultValues: {
                // IsGreenCardHolder: "N",
                //HasTaxCode1: "Y",
                //Education: "U",
                //WorkStatus: "E",
            }
        });

    // const finish = () => {
    //     if (isFromSuccess && WizardInstance.goToStep) {
    //         WizardInstance.goToNamedStep(nextStepName);
    //     }
    // };

    const submitForm = () => {
        if (!isValid) {
            trigger();
            setErrorMsg("提交資料失敗，請重新再試");
        } else {
            setIsProcessingNext(true);
            const data = getValues();
            AOSService.OpeningBooking(data.ClientName)
                .then(res => {
                    if (!res || !res.Result) {
                        setErrorMsg("提交資料失敗，請重新再試");
                    } else {
                        setIsFromSuccess(true);
                    }
                }).finally(() => {
                    setIsProcessingNext(false);
                });
        }
    };

    return (
        <div className="login-bg">
            <Container className="h-100 p-0 d-flex g-0">
                <form className="login-card my-0 p-0 my-auto mx-auto">
                    <Row className="justify-content-center">
                        <Col lg={6}>
                            <div className="p-3">
                                <div className="text-center mb-4">
                                    <img className="ffg-logo my-lg-4 my-2" src={logo} alt="" />

                                    <h4 className="slogan text-center"> 預約開戶 </h4>
                                </div>

                                {!isFromSuccess ? (
                                    <React.Fragment>
                                        <div className="px-4 my-2">
                                            <div className="input-group input-group-lg mb-3">
                                                <span className="input-group-text d-block text-right border-0 col-3" style={{ backgroundColor: "#fff" }}>
                                                    客戶名稱:
                                                </span>
                                                <input type="text"
                                                    {...register("ClientName")}
                                                    className={`form-control form-control-lg px-2 ${errors.ClientName ? 'is-invalid' : "border-0"}`}
                                                    placeholder="請輸入客戶名稱"
                                                />
                                                {errors.ClientName &&
                                                    <div className="invalid-feedback">
                                                        {errors.ClientName.message}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="px-4">
                                            我同意富昌金融集團向我提供/發送直接推廣信息。
                                        </div>

                                        <button type="button"
                                            className="btn btn-wizard col-sm-12 col-md-12 my-4"
                                            disabled={!isValid || IsProcessingNext}
                                            onClick={submitForm}
                                        >
                                            {IsProcessingNext && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                            確認
                                        </button>

                                        {errorMsg && (<div className="px-4 d-grid gap-2 col-12 mx-auto my-2 alert alert-warning" role="alert">{errorMsg}</div>)}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className="px-4 my-5 text-center">
                                            
                                            多謝選擇富昌預約開戶! 客服主任將稍後透過 +852 3108 3333 致電閣下辦理開戶手續，如有其他查詢，可使用 WhatsApp 6704 5661 聯絡我們。
                                            <p>
                                            <b>請提前準備以下開戶文件 : </b>
                                            (1) 有效的身份證 / 護照<br />
                                            (2) 三個月內有效的住址證明<br />
                                            </p>
                                        </div>
                                        {/* <button type="button"
                                            className="btn btn-wizard col-sm-12 col-md-12"
                                            onClick={finish}
                                        >
                                            Finish
                                        </button> */}
                                    </React.Fragment>
                                )}

                            </div>
                        </Col>
                    </Row>
                </form>
            </Container>
        </div>

        // <StepContainer
        //     showNavigation={true}
        //     onNext={goNext}
        //     nextLabel={'Submit'}
        //     {...props}
        // >
        //     {/* <PrettyPrintJson data={userRef} />  */}

        //     <Container className="h-100 p-0 d-flex g-0">
        //         <form className="login-card my-0 p-0 my-auto mx-auto">
        //             <Row className="justify-content-center">
        //                 <Col lg={6}>
        //                     <div className="p-3">
        //                         <div className="text-center mb-4">
        //                             <img className="ffg-logo my-lg-4 my-2" src={logo} alt="" />
        //                         </div>

        //                         <div className="px-4 my-2">
        //                             <div className={`input-group input-group-lg mb-3`}>
        //                                 <span className="input-group-text d-block text-center border-0 col-3" style={{ backgroundColor: "#fff" }}>
        //                                     姓名:
        //                                 </span>
        //                                 <input type="text"
        //                                     {...register("ClientName")}
        //                                     className={`form-control form-control-lg px-2 ${errors.ClientName ? 'is-invalid' : "border-0"}`}
        //                                     placeholder="請輸入姓名"
        //                                 />
        //                                 {errors.ClientName &&
        //                                     <div className="invalid-feedback">
        //                                         {errors.ClientName.message}
        //                                     </div>
        //                                 }
        //                             </div>
        //                         </div>
                                
        //                         {/* <div className="px-4 d-grid gap-2 col-12 mx-auto my-2">
        //                             <button type="button"
        //                                 disabled={!isValid}
        //                                 className="btn btn-block btn-danger"
        //                                 onClick={submitForm}
        //                             >
        //                                 OK
        //                             </button>
        //                         </div> */}

        //                         {errorMsg && (<div className="px-4 d-grid gap-2 col-12 mx-auto my-2 alert alert-warning" role="alert">{errorMsg}</div>)}
        //                     </div>
        //                 </Col>
        //             </Row>
        //         </form>
        //     </Container>
        // </StepContainer>
    );
}