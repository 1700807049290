import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserSettingsProvider from './components/UserSettingsProvider';
import ReactGA from 'react-ga';
//import useGTM from '@elgorditosalsero/react-gtm-hook'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

Sentry.init({
  dsn: "https://3245aa34f3f63e30a208043999251272@sentry.ffg.com.hk/2",
  integrations: [
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const Root = () => {

  const gtmParams = {
    id: 'GTM-TLPGZK4',
    //dataLayerName: 'customDataLayerName'
  }
  const TRACKING_ID = "UA-70032979-4"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  //const { init, UseGTMHookProvider } = useGTM()

  return (
    <>
    <UserSettingsProvider>
      <React.StrictMode>
        <GTMProvider state={gtmParams}>
          <App />
        </GTMProvider>
      </React.StrictMode>
    </UserSettingsProvider>
    </>
  )
};

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
