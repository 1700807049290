import React from "react";
import { useId, nextId } from "react-id-generator";
import styled from 'styled-components'

const Wrapper = styled.div`
    display: inline-flex;
    position: relative;
    padding: 4px;
    margin: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
        position: absolute;
        top: 23px;
        left: 16px;
        height: 20px;
        width: 20px;
        background-color: #ced4da;
        border-radius: 50%;
    }

    & input:checked ~ label > .checkmark:after {
        display: block;
    }

    & input:checked ~ label > .checkmark {
        background-color: rgb(204,0,16);
    }

    &:hover input:enabled ~ label {
        color: rgb(204,0,16);
        border: 1px solid rgb(204,0,16);
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 0px;
        width: 8px;
        height: 16px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
`;

const Label = styled.label`
    display: inline-grid;
    width: 100%;
    cursor: pointer;
    padding: 16px !important;
    padding-left: 40px !important;
    border: 1px solid #ced4da;
    border-radius: 30px;
`;

const Input = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + label {
        color: rgb(204,0,16);
        border: 1px solid rgb(204,0,16);
    }
`;


export const FFGRadio = ({ register, name, value, className, checked, children, ...rest }) => {
    //export const FFGRadio = ({ name, value, defaultValue, onChange, ...rest }) => {
    //export const FFGRadio = React.forwardRef(
    //     ({ name, value, onChange, defaultValue, ...rest }, forwardedRef) => {
    const [htmlId] = useId();

    // const toggleCheck = (e) => {
    //     console.log('toggle');
    //     const { value } = e.currentTarget;
    //     console.log(e.currentTarget, "currentTarget");
    //     onChange(value);
    // };

    // React.useEffect(() => {
    //     console.log('useEffect');
    //     if(defaultValue) {
    //         console.log('checked');
    //         onChange(value);
    //     }
    // }, []);

    return (
        <Wrapper className={className}>
            <Input {...register}
                id={htmlId} type="radio" name={name} value={value} checked={checked} {...rest}
            />
            <Label htmlFor={htmlId}>
                {children}
            </Label>
            <span className="checkmark"></span>
        </Wrapper>
    );
};


export const FFGCheckbox = ({register, className, children, checked, ...rest}) => {
    const htmlId = useId();

    return (
        <Wrapper className={className}>
            <Input {...register} id={htmlId} type="checkbox" {...rest} checked={checked} />
            <Label htmlFor={htmlId}>
                {children}
            </Label>
            <span className="checkmark"></span>
        </Wrapper>
    );
};


export const RadioButtonList = props => {
    const { Items, defaultValue, className, name, onChange, setValueAs } = props;
    const [radioState, setRadioState] = React.useState();
    const htmlId = useId(Items.length);

    React.useEffect(() => {
        setRadioState(defaultValue);
        //console.log("radioState:", radioState, "name:", name);
    }, [defaultValue])

    return (<>
        {
            Items.map((item, idx) => {
                return (
                    <Wrapper key={htmlId[idx]} className={className}>
                        <Input
                            id={htmlId[idx]} type="radio"
                            name={name}
                            value={item.value}
                            checked={item.name === radioState}
                            onChange={(e) => {
                                setRadioState(e.target.value);
                                if (setValueAs) {
                                    //console.log(setValueAs(e.target.value), "setValueAs");
                                    onChange(setValueAs(e.target.value));
                                } else {
                                    //console.log(e.target.value, "default");
                                    onChange(e.target.value);
                                }
                            }}
                        />
                        <Label htmlFor={htmlId[idx]}>
                            {item.label}
                            <span className="checkmark"></span>
                        </Label>
                    </Wrapper>
                );
            }
            )
        }
    </>);
};