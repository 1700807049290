import React, { useEffect } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import SignaturePad from 'react-signature-pad-wrapper';
import './ClientSignature.css';

const ClientSignature = (props) => {
    const signaturePad = React.createRef();
    const { userRef } = useUserSettings();
    //const [Sign, setSign] = React.useState();
    const [NextStep, setNextStep] = React.useState();

    const clear = () => {
        signaturePad?.current?.clear();
    }

    useEffect(() => {
        if (userRef.IDCardOrigin === "CHN" && !userRef.HasHKGBankAcc) {
            setNextStep("BioCheck");
        } else {
            setNextStep("AOStatusInfo");
        }
    }, [])

    const convertBase64ToFile = function (image) {
        const byteString = atob(image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
          ia[i] = byteString.charCodeAt(i);
        }
        const newBlob = new Blob([ab], {
          type: 'image/png',
        });
        return newBlob;
      };
          

    const goNext = () => {
        if (signaturePad?.current?.isEmpty()) {
            alert("請簽署後再提交");
            return new Promise(resolve => resolve(false));
        } else {
            return new Promise(resolve => {
                AOSServices.UploadSignature(userRef.RefCode, NextStep, convertBase64ToFile(signaturePad.current.toDataURL()))
                .then(res => {
                    if (!res || !res.Result) {
                        alert("提交資料失敗，請重新再試");
                    }
                    resolve(res.Result);
                });
            });
        }
    };

    return (
        <StepContainer showNavigation={true}
            nextStepName={NextStep}
            onNext={goNext}
            {...props}>

            <div className="row">
                <div className="col-12">
                    <h3 className="pt-3"> 客戶簽署 </h3>
                </div>
            </div>


            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body text-start">
                            本人(以下簽署人) 謹此確認本表格內所有以電子形式輸入的資料均屬完整及正確。本人承認、明白及同意富昌證券及／或富昌期貨（視具體情況而定）將可使用及依賴該等資料以操作本人以上的帳戶。本人再承認並確認富昌證券及／或富昌期貨就開戶表格的內容按照本人明白的語言(英文或中文)獲得充分的解釋，並已閱讀及同意有關網上開戶的條款。
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-5">
                    <div className="mt-3 col col-lg Signature-Pad">
                        <SignaturePad options={{ minWidth: 0.5, maxWidth: 2, dotSize: 0.5, penColor: 'rgb(66, 66, 66)' }}
                            redrawOnResize={true}
                            ref={signaturePad} />
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button className="btn btn-outline-secondary me-md-2" type="button" onClick={() => clear()}>
                            清除簽名
                        </button>
                         {/* <button className="btn btn-danger" type="button" onClick={() => {
                            console.log(signaturePad.current.toDataURL());
                            window.open(signaturePad.current.toDataURL());
                        }}>
                            確認簽名
                        </button>  */}
                    </div>

                </div>
            </div>

        </StepContainer>
    );
};


export default ClientSignature;