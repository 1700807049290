import React from "react";
import styled from 'styled-components'
// import IconSelfie from '../../assets/icon_selfie.svg';
import VideoRecorder from 'react-video-recorder'

const VideoUploadWapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid #ddd;

    > div[class^="video-recorder__Wrapper"] {
        background-color: #fff2f2 !important;
        border: 3px solid #c1afaf;
        border-radius: 10px;
        margin-top: 10px;
    }
`;


const VideoPreview = styled.video`
    display: block;
    margin: 0;
    max-height:50vh;
    background-color: #fff2f2 !important;
    border: 3px solid #c1afaf;
    border-radius: 10px;
    margin-top: 10px;
`;

export default function VideoInput(props) {
    const { width, height, onFileChanged } = props;

    const [source, setSource] = React.useState();

    const handleFileSize = (blob) => {
        const blobSize = blob?.size || 0;
        if (blobSize > 0) {
            if (blobSize <= (1048576 * 10)) {
                const url = URL.createObjectURL(blob);
                setSource(url);
                //console.log(blob);
                onFileChanged(blob);
            } else {
                alert("自拍影像檔不能大於 10MB");
            }
        } else {
            setSource(null);
        }
    }

    const resetVideo = () => {
        setSource(null);
    }


    const loadText = (key) => {

        //alert(key);
        if (key === 'PRESS')  return "按";
        if (key === 'REC')  return "紅點";
        if (key === 'WHEN READY')  return "開始錄影";
        if (key === 'Turn my camera ON') return "按此打開相機功能";
        if (key === 'Record a video') return "按此錄影";
        return key;
    }


    return (
        <VideoUploadWapper>
            {!source && (<VideoRecorder
                isFlipped={false}
                isOnInitially
                //countdownTime={undefined}
                countdownTime={0}
                t={loadText}
                timeLimit={5000}
                mimeType={undefined}//"video/mp4" //"video/webm;codecs=vp8,opus"
                //mimeType="video/mp4"
                //mimeType={undefined}
                constraints={{
                    audio: false,
                    video: true,
                    // video: {
                    //     width: { exact: width, ideal: width },
                    //     height: { exact: height, ideal: height },
                    //     aspectRatio: { exact: 0.7500000001, ideal: 0.7500000001 },
                    //     resizeMode: "crop-and-scale"
                    // }
                }}
                onRecordingComplete={(videoBlob) => {
                    // Do something with the video...
                    //console.log("videoBlob", videoBlob);
                    handleFileSize(videoBlob);
                    //push("/videoPreview", { videoBlob });
                }}/*
            renderActions={({
                onStartRecording,
                onStopRecording,
                isCameraOn,
                streamIsReady,
                isVideoInputSupported,
                isInlineRecordingSupported,
                thereWasAnError,
                isConnecting,
                isRunningCountdown,
                isReplayingVideo
            }) => {
                console.log({ isReplayingVideo });
                if (
                    (!isInlineRecordingSupported && !isVideoInputSupported) ||
                    thereWasAnError ||
                    isConnecting ||
                    isRunningCountdown ||
                    isReplayingVideo
                ) {
                    return null;
                }

                return (
                    <div style={{ position: "absolute", bottom: "10%" }}>
                        <button class="btn btn-success p-3 m-5"
                            onClick={() => {
                                if (isCameraOn && streamIsReady) {
                                    onStartRecording();
                                }
                            }}
                        >
                            錄製
                        </button>
                        <button class="btn btn-outline-warning p-3 m-5" onClick={onStopRecording}>停止</button>
                    </div>
                );
            }}*/
            />)
            }
            {source && (
                <>
                    <VideoPreview
                        // width="100%"
                        // height={height}
                        controls
                        src={source}
                    />

                    <div className="d-grid gap-2 py-3">
                        <button className="btn btn-outline-secondary btn-lg" type="button" onClick={resetVideo}> 重新錄製 </button>
                    </div>
                </>
            )}
        </VideoUploadWapper>
    );
};



