import React from "react";
import "./promotion.css";

export const Future_0_Comission = (props) => {
    
    return (
        <div className="pro-bg">
            <div className="col-12">
                <h3 className="pt-3 pro-header"> 優惠1：環期、港期/期權 0佣優惠 </h3>
            </div>

            <div className="col-11 offset-1">
                <p>新客戶在開戶首2個月內 （開戶月+下一個月），凡經電子渠道交易特選30款環球期貨、香港期貨及期權產品，首100張佣金 （混合產品計算），全數退回，HK$1不收，全港獨有！</p>
            </div>

            <div className="col-12">
                <h3 className="pt-3 pro-header"> 優惠2：Trade 400張再送200張 </h3>
            </div>

            <div className="col-11 offset-1">
                <p>新開客戶於優惠期內 (開戶月+下一個月) 總交易張數達400張或以上，下一個月再送您多200張免佣優惠*，如此類推，不設贈送次數上限。 <br />
				*如優惠月期間總張數未能達至400張或以上，續送優惠便會終止，不可順延。</p>
            </div>

            <div className="col-12">
                <h3 className="pt-3 pro-header"> 優惠3：免費港期、港權、美期串流報價 </h3>
            </div>

            <div className="col-11 offset-1">
                <ol>
                    <li>新客戶成功開立期貨賬戶後，毋須登記，即可獲享3個月免費港期、港權及美期串流報價服務（下稱「港期、港權及美期免費報價服務」）。 </li>
                    <li>「港期、港權及美期免費報價服務」優惠期內，新客戶如透過「富昌期運通」APP進行港期、港權、美期 1次或以上交易，下月即可繼續免費享用相關報價服務，如此類推。如未能達到以上指定交易次數，免費報價服務便會在贈送期後結束而不作另行通知。 </li>
                    <li>免費報價產品範圍：本公司於以下交易所提供之交易產品，包括香港期貨交易所 (HKEX)、芝加哥商業交易所 (CME)、芝加哥商業及商品交易所 (CBOT)、紐約商業交易所 (NYMEX)、紐約期貨交易所 (NYBOT) 及紐約金屬交易所 (COMEX) 。 </li>
                    <li>此免費報價服務適用於本公司的手機版及電腦版交易平台。</li>
                </ol>
            </div>

            <div className="col-11 offset-1">
                

                <p><b>其他條款及細則：</b></p>

                <ol>
                    <li>
                        推廣期 : 2024年3月1日起，直至另行通知。
                        </li>
                    <li>
                    此推廣活動只適用於本公司之新客戶。 
                    </li>
                    <li>
                    新客戶是指 : 在開戶當日前12個月內未曾以公司、個人或聯名方式持有任何本公司的戶口。 
                    </li>
                    <li>
                    新客戶必須以電郵方式接收日/月結單，方可獲得此優惠。 
                    </li>
                    <li>
                    本優惠適用於新客戶開立的個人及/或聯名證券交易帳戶。 
                    </li>
                    <li>
                    所有上述之優惠不可兌換成現金或其他貨品、服務及優惠。 
                    </li>
                    <li>
                    新客戶如自行終止帳戶，又或被本公司因新客戶的不恰當行為(包括但不只限於 : 對本公司的欺詐、誣捏、惡意中傷等)而終止帳戶，相關優惠則不會獲得退回。 
                    </li>
                    <li>
                    本公司保留隨時更改本優惠條款及細則的權利。本公司亦可能運用酌情權取消此優惠而毋須事先通知。 
                    </li>
                    <li>
                    如有任何爭議，本公司保留最終決定權。 
                    </li>
                    <li>
                    本優惠並非及不應被視為進行任何投資的邀請、要約或投資建議。客戶應注意，投資涉及風險，投資產品價格可升可跌，投資前請充分理解產品風險，並應就此諮詢專業顧問。
                    </li>
                </ol>
            </div>
        </div>
    );
};
