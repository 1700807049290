import { createContext, useContext, useState } from "react";
import { IntlProvider } from 'react-intl';
import zh_CHT from "../lang/zh_CHT";
import zh_CHS from "../lang/zh_CHS";


export const UserSettingsContext = createContext();
export const useUserSettings = () => useContext(UserSettingsContext);

const defaultLang = "zh-hk";

const UserSettingsProvider = ({ children, lang }) => {
  const [locale, setLocale] = useState(defaultLang);
  const [userRef, setUserRef]  = useState({});

  // const saveSettings = (values) => {
  //  setCurrentSettings(values)
  // };
  // const changeLang = (lang) => {
  //   setLocale(lang);
  // };

  const changeLang = (lang) => setLocale(lang);
  

  const getMessage = (l) => {
    switch (l) {
      case "zh-hk": return zh_CHT;
      case "zh-cn": return zh_CHS;
      default:
        return zh_CHT;
    }
  };

  return (<UserSettingsContext.Provider value={{ locale, changeLang, userRef, setUserRef }}>
    <IntlProvider key={locale} locale={locale} defaultLocale="zh-hk" messages={getMessage(locale)} >
      {children}
    </IntlProvider>
  </UserSettingsContext.Provider>);
};

export default UserSettingsProvider;
