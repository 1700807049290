import React from "react";
import "./promotion.css";

export const TOTIELONG_SEC = (props) => {
    
    return (
        <div  className="pro-bg">
            <div className="col-12">
                <h3 className="pt-3 pro-header"> 新客戶「2個月港股零佣金優惠」 </h3>
            </div>

            <div className="col-11 offset-1">
                <p>
                    <b className="pro-sub-head"> 《第1賞》 </b><br />
                    新客戶於富昌證券有限公司（下文簡稱「本公司」）成功開立證券交易帳戶，即可享有「2個月港股零佣金優惠」（下文簡稱「本優惠」），詳情如下：新開立的證券交易帳戶，優惠期由開戶當月及其後2個月，港股網上交易佣金費用全免。
                </p>

                <p>
                    <b className="pro-sub-head"> 《第2賞》 </b><br />
                    免佣期後，每月佣金達到港幣 1500 元，將可免費獲得 megahub 普通版報價服務 ( 價值港幣 380 元 )。
                </p>

                <p>
                    <b className="pro-sub-head"> 《第3賞》 </b><br />
                    每月佣金達到港幣 2000 元，將可獲得港幣 200 元佣金回贈。 而每月佣金達到港 幣 3000 元，將可獲得港幣 500 元佣金回贈。(回贈將會於下個月內存入證券戶口)
                </p>


                <p><b>其他條款及細則</b></p>
                <ol>
                    <li>
                    推廣期 : 即日起至2023年4月30日。 
                    </li>
                    <li>
                    此推廣活動只適用於本公司之新客戶，並在開戶時提供相關優惠碼方可獲得以上優惠。
                    </li>
                    <li>
                    新客戶是指 : 在開戶當日前12個月內未曾以公司、個人或聯名方式持有任何本公司的戶口。
                    </li>
                    <li>
                    每月佣金計算方法: 富昌金融集團內之所有個人戶口，包括港股證券、期貨及期權帳戶和美股證券、期貨及期權帳戶之佣金總和。
                    </li>
                    <li>
                    本優惠的是指新客戶在優惠期內，港股網上交易佣金費用全免，但不包括:印花稅、證監會交易徵費、聯交所交易費、香港結算公司結算費、財務匯報局交易徵費、交易稅及其他所有雜費。 
                    </li>
                    <li>
                    新客戶必須以電郵方式接收日/月結單，方可獲得此優惠。 
                    </li>
                    <li>
                    本優惠適用於新客戶開立的個人及聯名立證券交易帳戶。 
                    </li>
                    <li>
                    所有上述之優惠不可兌換成現金或其他貨品、服務及優惠。 
                    </li>
                    <li>
                    本公司保留隨時更改本優惠條款及細則的權利。本行亦可能運用酌情權取消此優惠而毋須事先通知。 
                    </li>
                    <li>
                    如有任何爭議，本公司保留最終決定權。 
                    </li>
                    <li>
                    本優惠並非及不應被視為進行任何投資的邀請、要約或投資建議。客戶應注意，投資涉及風險，投資產品價格可升可跌，投資前請充分理解產品風險，並應就此諮詢專業顧問。
                    </li>
                </ol>
                
            </div>
        </div>
    );
};
