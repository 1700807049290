import React, { useState, useEffect, useCallback } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import { FBLabel, PrettyPrintJson } from '../../services/Utils';
import { SwitchBoxWapper, SwitchBox, SwitchBoxLabel } from '../SelfDisclosure/SwitchBoxStyle';

import { format } from 'date-fns';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


const RiskDisclosure = (props) => {
    const { userRef } = useUserSettings();
    const [AudioSource, setAudioSource] = useState();
    const [PlayList, setPlayList] = useState([]);
    const [PlaybackRate, setPlaybackRate] = useState(1);
    const [RiskLanguage, setRiskLanguage] = useState("zh_hk");
    const [CurrentAudioIndex, setCurrentAudioIndex] = useState(0);
    const [RiskCompleted, setRiskCompleted] = useState(false);

    const Player = React.createRef();

    const validationSchema = Yup.object().shape({
        UnstandandRiskDisclosure: Yup.boolean().oneOf([true], "請閱讀及同意以上有關聲明"),
        AcceptRiskDisclosure: Yup.boolean().oneOf([true], "請閱讀及同意以上有關聲明"),
        PersonalInfoCollection: Yup.boolean().oneOf([true], "請閱讀及同意以上有關聲明"),
        AcceptDisclaimer: Yup.boolean().oneOf([true], "請閱讀及同意以上有關聲明"),
        SelectedLang: Yup.string().nullable(),
        StartAt: Yup.date().nullable(),
        CompletedAt: Yup.date().nullable(),
    });


    const { register, trigger, watch, getValues, setValue, control,
        formState: { isValid, errors }, clearErrors } = useForm({
            mode: "all",
            resolver: yupResolver(validationSchema),
            defaultValues: {
            }
        });

    const watchAllFields = watch();

    useEffect(() => {
        AOSServices.GetRiskDisclosure(userRef.RefCode)
            .then((resp) => {
                if (resp && resp.Result) {
                    setAudioSource(resp.Info);
                    // initial value
                    setValue("SelectedLang", RiskLanguage);
                    setValue("StaffName", resp.Info.StaffName);
                    setValue("Licence", resp.Info.Licence);
                    setValue("PlayList", resp.Info.PlayList);
                }
            });
    }, []);

    useEffect(() => {
        ChangeRiskLanaguage(RiskLanguage);
    }, [AudioSource]);

    useEffect(() => {
        //console.log("change PlaybackRate")
        if (Player.current?.audio?.current) {
            Player.current.audio.current.playbackRate = PlaybackRate;
        }
    }, [PlaybackRate, CurrentAudioIndex, RiskLanguage]);

    const ChangePlaybackRate = () => {
        //console.log(Player.current?.audio?.current?.playbackRate, "playbackRate");
        if (Player.current?.audio?.current) {
            setPlaybackRate(PlaybackRate === 1 ? 1.6 : 1);
        }
    };

    const GetDisclosureType = () => PlayList[CurrentAudioIndex]?.DisclosureType;

    const GetDisclosureTypeList = () => PlayList?.map(a => a.DisclosureType);

    const HandlePlayerStart = async (e) => {
        const s = getValues("StartAt");
        if (!s) setValue("StartAt", new Date());

        // Allow client to procceed after 15s
        setTimeout(() => {
            setRiskCompleted(true);

            const end = getValues("CompletedAt");
            if (!end)
                setValue("CompletedAt", new Date());        
            
        }, 15000)
    };

    const HandleChangeRiskLanguage = (e) => {
        const lang = e.target.value;
        ChangeRiskLanaguage(lang);
        setValue("SelectedLang", lang);
    }

    const ChangeRiskLanaguage = (lang) => {
        setRiskLanguage(lang);
        const playlist = [];
        // console.log(lang, "lang");
        // console.log(RiskLanguage, "RiskLanguage");
        // console.log(AudioSource, "AudioSource");
        AudioSource?.PlayList.forEach(obj => {
            if (obj.LangCode === lang) {
                playlist.push(obj);
            }
        });
        setPlayList(playlist);
        setCurrentAudioIndex(0);
    };

    const GetRiskTitle = (RType) => {
        switch (RType) {
            case "sec": return "證券風險聲明";
            case "ft": return "期貨風險聲明";
            case "opt": return "期權風險聲明";
            default: return "";
        }
    }

    const HandlePlayerFinished = (e) => {
        //console.log(e, "HandlePlayerFinished");

        const idx = CurrentAudioIndex + 1;
        //console.log(PlayList.length, "PlayList.length");
        if (PlayList.length > idx) {
            setCurrentAudioIndex(idx);
        } else if (idx >= PlayList.length) {
            setRiskCompleted(true);

            const end = getValues("CompletedAt");
            if (!end)
                setValue("CompletedAt", new Date());
        }
    };

    const HandlePlayerLoadedData = (e) => {
        //console.log(e, "HandlePlayerLoadedData");
    };

    const ProfilePic = () => {
        const dst = GetDisclosureType();
        //console.log(dst);

        //return dst ? (dst === "sec" ? "/rd/20210608.jpg" : "/rd/20200417.jpg") : "";
        //return dst ? (RiskLanguage === "zh_hk" ? "/rd/20230927.jpg" : "/rd/anonymous.png") : "";
        return dst ? "/rd/anonymous.png" : "";
    };

    const goNext = () => {
        if (!isValid) {
            trigger();
            return new Promise(resolve => resolve(false));
        } else {
            const data = getValues();
            //console.log(data);
            //return new Promise(resolve => resolve(false));

            return new Promise(resolve => {
                AOSServices.SaveRiskDisclosure(userRef.RefCode, data, "ClientSignature")
                    .then(res => {
                        if (!res || !res.Result) {
                            alert("提交資料失敗，請重新再試");
                        }
                        resolve(res.Result);
                    });
            });
        }
    };


    const cssFormLabelCol = "col-sm-3 col-xs-12 col-form-label my-0 my-md-2";
    const cssFormInputCol = "col-sm-9 col-xs-12 my-0 my-md-2";

    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            nextDisabled={!RiskCompleted}
            {...props}>

            {/* <PrettyPrintJson data={watchAllFields}></PrettyPrintJson> */}

            <div className="row">
                <div className="col-12">
                    <h3 className="pt-3"> 風險聲明 </h3>
                </div>

                <div className="col-12">
                    <h5 className="pt-2 px-0 fb-red"> {GetRiskTitle(GetDisclosureType())} {`${CurrentAudioIndex + 1}/${PlayList?.length}`}</h5>
                </div>

                <div className="col-3 m-auto">
                    <img src={ProfilePic()} className="rounded" alt=""></img>
                    {/* <div> {AudioSource?.StaffName} </div> */}
                </div>
                <div className="col-9">

                    <div className="row">
                        <FBLabel className="col-sm-2 col-xs-12 col-form-label my-0 my-md-2" text="請選擇語言:" />

                        <div className="col-sm-5 col-xs-12 d-grid">
                            <button type="button"
                                value="zh_hk"
                                onClick={HandleChangeRiskLanguage}
                                className={`btn btn-lg my-2 ${RiskLanguage === "zh_hk" ? "btn-danger" : "btn-outline-danger"}`}> 廣東話 </button>
                        </div>

                        <div className="col-sm-5 col-xs-12 d-grid">
                            <button type="button"
                                value="zh_cn"
                                onClick={HandleChangeRiskLanguage}
                                className={`btn btn-lg my-2 ${RiskLanguage === "zh_cn" ? "btn-danger" : "btn-outline-danger"}`}> 普通話 </button>
                        </div>

                        <div className="col-12">
                            <AudioPlayer
                                style={{ borderRadius: "10px" }}
                                ref={Player}
                                autoPlay={true}
                                // src="/rd/zh_hk_s_20210608.mp3"
                                src={PlayList[CurrentAudioIndex]?.Src}
                                showJumpControls={false}
                                hasDefaultKeyBindings={false}
                                autoPlayAfterSrcChange={true}
                                showFilledProgress={false}
                                onPlay={HandlePlayerStart}
                                onEnded={HandlePlayerFinished}
                                onLoadedData={HandlePlayerLoadedData}
                                layout="horizontal"
                                customProgressBarSection={
                                    [
                                        RHAP_UI.CURRENT_TIME,
                                        <div>/</div>,
                                        RHAP_UI.DURATION
                                    ]
                                }
                                customControlsSection={
                                    [
                                        RHAP_UI.MAIN_CONTROLS,
                                        RHAP_UI.VOLUME_CONTROLS,
                                        <div className="rhap_main-controls"><button className="ms-3" style={{ border: "1px solid #999", borderRadius: "3px", fontSize: "1em" }} onClick={ChangePlaybackRate}>{PlaybackRate}x</button></div>,
                                    ]
                                }
                            />
                        </div>

                    </div>

                </div>
            </div>

            <div className="row">

                <div className="col-12">
                    <hr className="dot-line" />
                </div>

                <div className="col-12 text-start">
                    <div className="card">
                    {(RiskLanguage === "zh_hk") ? (
                        <>
                        {GetDisclosureTypeList().includes("sec") && (
                            <div className="card-body">
                                我是郭燕芬，證監會牌照中央編號係：A Y C 902，代表富昌證券有限公司跟閣下說明一下有關戶口的投資風險：<br/>
                                網上買賣 : 如果閣下透過電子交易系統進行買賣，就必須承擔任何系統及網絡上之相關風險。如：網絡不穩定有關互聯網交易的注意事項，請閣下自行參閱已載於本公司網頁之客戶協議內互聯網證券/期貨交易協議書的部分。
                                證券價格可升可跌，有可能變成毫無價值。窩輪、牛熊證、創業板股份風險較高，價格非常波動及流通量比較低，如牛熊證觸及回收價，將即時被發行商強制收回。買賣證券未必一定能夠賺取利潤，反而可能會招致損失。
                                如果閣下有開立一個孖展賬戶，請留意用抵押品取得融資的虧損風險可能很大，有機會超過您存放在作為抵押品的現金和任何其他資產的價值。你可能會在短時間內被要求存入額外的保證金款額或繳付利息。假如你未能在指定的時間內支付所需的保證金款額或利息，你的抵押品可能會在未經你的同意下被出售。此外，你將要為你的帳戶內因此而出現的任何短欠數額及需繳付的利息負責。
                                買賣環球股票或滬深港通產品時，閣下可能需要承擔外匯轉換風險。閣下亦須注意，有些環球股票市場(包括中國及滬深港通市場)跌幅或升幅超過特定百分比時，有關市場可能會暫停任何股票交易。另外，當滬深港通市場每日額度用盡時，閣下可能不能買入有關市場的股票。
                                假如你對本風險披露聲明的內容有不明白之處，應尋求獨立的專業意見。<br />
                            </div>
                        )}

                        {GetDisclosureTypeList().includes("ft") && (
                            <div className="card-body">
                                我是郭燕芬，證監會牌照中央編號係：A Y C 902，代表富昌期貨有限公司跟閣下說明一下有關戶口的投資風險：<br/>
                                網上買賣 : 如果閣下透過電子交易系統進行買賣，就必須承擔任何系統及網絡上之相關風險。如：網絡不穩定有關互聯網交易的注意事項，請閣下自行參閱已載於本公司網頁之客戶協議內互聯網證券/期貨交易協議書的部分。
                                買賣期貨合約或期權涉及摃捍形式運作，虧蝕風險可能極大。在若干情況下，你所蒙受的虧蝕可能會超過最初存入的保證金數額。即使你設定了備用指示，例如 “止損  ”或 “限價 ”等指示，亦未必能夠避免損失。市場情況可能使該等指示無法執行。你可能會在短時間內被要求存入額外的保證金。假如未能在指定的時間內提供所需數額，你的未平倉合約可能會被平倉。然而，你仍然要對你的帳戶內任何因此而出現的短欠數額負責。如果你買賣期權，便應熟悉行使期權及期權到期時的權利與責任。
                                買賣環球期貨產品時，閣下可能需要承擔外匯轉換風險。閣下亦須注意，有些環球期貨市場跌幅或升幅超過特定百分比時，有關市場可能會暫停任何期貨交易。
                                假如你對本風險披露聲明的內容有不明白之處，應尋求獨立的專業意見。<br/>
                            </div>
                        )}

                        {GetDisclosureTypeList().includes("opt") && (
                            <div className="card-body">
                                我是郭燕芬，證監會牌照中央編號係：A Y C 902，代表富昌證券有限公司跟閣下說明一下有關戶口的投資風險：<br/>
                                網上買賣 : 如果閣下透過電子交易系統進行買賣，就必須承擔任何系統及網絡上之相關風險。如：網絡不穩定有關互聯網交易的注意事項，請閣下自行參閱已載於本公司網頁之客戶協議內互聯網證券/期貨交易協議書的部分。
                                證券價格可升可跌，有可能變成毫無價值。窩輪、牛熊證、創業板股份風險較高，價格非常波動及流通量比較低，如牛熊證觸及回收價，將即時被發行商強制收回。買賣證券未必一定能夠賺取利潤，反而可能會招致損失。
                                如果閣下有開立一個孖展賬戶，請留意用抵押品取得融資的虧損風險可能很大，有機會超過您存放在作為抵押品的現金和任何其他資產的價值。你可能會在短時間內被要求存入額外的保證金款額或繳付利息。假如你未能在指定的時間內支付所需的保證金款額或利息，你的抵押品可能會在未經你的同意下被出售。此外，你將要為你的帳戶內因此而出現的任何短欠數額及需繳付的利息負責。
                                買賣環球股票或滬深港通產品時，閣下可能需要承擔外匯轉換風險。閣下亦須注意，有些環球股票市場(包括中國及滬深港通市場)跌幅或升幅超過特定百分比時，有關市場可能會暫停任何股票交易。另外，當滬深港通市場每日額度用盡時，閣下可能不能買入有關市場的股票。
                                假如你對本風險披露聲明的內容有不明白之處，應尋求獨立的專業意見。<br/>

                                我是郭燕芬，證監會牌照中央編號係：A Y C 902，代表富昌證券有限公司跟閣下說明一下有關戶口的投資風險：<br/>
                                網上買賣 : 如果閣下透過電子交易系統進行買賣，就必須承擔任何系統及網絡上之相關風險。如：網絡不穩定有關互聯網交易的注意事項，請閣下自行參閱已載於本公司網頁之客戶協議內互聯網證券/期貨交易協議書的部分。
                                買賣股票期權合約涉及摃捍形式運作，虧蝕風險可能極大。在若干情況下，你所蒙受的虧蝕可能會超過最初存入的保證金數額。即使你設定了備用指示，例如 “止損 ”或 “限價 ”等指示，亦未必能夠避免損失。市場情況可能使該等指示無法執行，你可能會在短時間內被要求存入額外的保證金。假如未能在指定的時間內提供所需數額，你的未平倉合約可能會被平倉。然而，你仍然要對你的帳戶內任何因此而出現的短欠數額負責。如果你買賣股票期權，便應熟悉行使股票期權及股票期權到期時的權利與責任；若買賣環球股票期權，閣下亦須注意可能需要承擔外匯轉換風險。
                                假如你對本風險披露聲明的內容有不明白之處，應尋求獨立的專業意見。<br/>
                            </div>
                        )}
                        </>
                    ) : (
                        <>
                        {GetDisclosureTypeList().includes("sec") && (
                            <div className="card-body">
                                我是郭燕芬，證監會牌照中央編號係：AYC902，代表富昌證券有限公司跟閣下說明一下有關戶口的投資風險：<br />
                                網上買賣 : 如果閣下透過電子交易系統進行買賣，就必須承擔任何系統及網絡上之相關風險。如：網絡不穩定有關互聯網交易的注意事項，請閣下自行參閱已載於本公司網頁之客戶協議內互聯網證券/期貨交易協議書的部分。<br />
                                證券價格可升可跌，有可能變成毫無價值。窩輪、牛熊證、創業板股份風險較高，價格非常波動及流通量比較低，如牛熊證觸及回收價，將即時被發行商強制收回。買賣證券未必一定能夠賺取利潤，反而可能會招致損失。<br />
                                如果閣下有開立一個孖展賬戶，請留意用抵押品取得融資的虧損風險可能很大，有機會超過您存放在作為抵押品的現金和任何其他資產的價值。你可能會在短時間內被要求存入額外的保證金款額或繳付利息。假如你未能在指定的時間內支付所需的保證金款額或利息，你的抵押品可能會在未經你的同意下被出售。此外，你將要為你的帳戶內因此而出現的任何短欠數額及需繳付的利息負責。<br />
                                買賣環球股票或滬深港通產品時，閣下可能需要承擔外匯轉換風險。閣下亦須注意，有些環球股票市場(包括中國及滬深港通市場)跌幅或升幅超過特定百分比時，有關市場可能會暫停任何股票交易。另外，當滬深港通市場每日額度用盡時，閣下可能不能買入有關市場的股票。<br />
                                假如你對本風險披露聲明的內容有不明白之處，應尋求獨立的專業意見。<br />
                            </div>
                        )}

                        {GetDisclosureTypeList().includes("ft") && (
                            <div className="card-body">
                                我是郭燕芬，證監會牌照中央編號係：AYC902，代表富昌期貨有限公司跟閣下說明一下有關戶口的投資風險：<br />
                                網上買賣 : 如果閣下透過電子交易系統進行買賣，就必須承擔任何系統及網絡上之相關風險。如：網絡不穩定有關互聯網交易的注意事項，請閣下自行參閱已載於本公司網頁之客戶協議內互聯網證券/期貨交易協議書的部分。<br />
                                買賣期貨合約或期權涉及摃捍形式運作，虧蝕風險可能極大。在若干情況下，你所蒙受的虧蝕可能會超過最初存入的保證金數額。即使你設定了備用指示，例如 “止損  ”或 “限價 ”等指示，亦未必能夠避免損失。市場情況可能使該等指示無法執行。你可能會在短時間內被要求存入額外的保證金。假如未能在指定的時間內提供所需數額，你的未平倉合約可能會被平倉。然而，你仍然要對你的帳戶內任何因此而出現的短欠數額負責。如果你買賣期權，便應熟悉行使期權及期權到期時的權利與責任。<br />
                                買賣環球期貨產品時，閣下可能需要承擔外匯轉換風險。閣下亦須注意，有些環球期貨市場跌幅或升幅超過特定百分比時，有關市場可能會暫停任何期貨交易。<br />
                                假如你對本風險披露聲明的內容有不明白之處，應尋求獨立的專業意見。<br />
                            </div>
                        )}

                        {GetDisclosureTypeList().includes("opt") && (
                            <div className="card-body">
                                我是郭燕芬，證監會牌照中央編號係：AYC902，代表富昌證券有限公司跟閣下說明一下有關戶口的投資風險：<br />
                                網上買賣 : 如果閣下透過電子交易系統進行買賣，就必須承擔任何系統及網絡上之相關風險。如：網絡不穩定有關互聯網交易的注意事項，請閣下自行參閱已載於本公司網頁之客戶協議內互聯網證券/期貨交易協議書的部分。<br />
                                買賣股票期權合約涉及摃捍形式運作，虧蝕風險可能極大。在若干情況下，你所蒙受的虧蝕可能會超過最初存入的保證金數額。即使你設定了備用指示，例如 “止損 ”或 “限價 ”等指示，亦未必能夠避免損失。市場情況可能使該等指示無法執行，你可能會在短時間內被要求存入額外的保證金。假如未能在指定的時間內提供所需數額，你的未平倉合約可能會被平倉。然而，你仍然要對你的帳戶內任何因此而出現的短欠數額負責。如果你買賣股票期權，便應熟悉行使股票期權及股票期權到期時的權利與責任；若買賣環球股票期權，閣下亦須注意可能需要承擔外匯轉換風險。<br />
                                假如你對本風險披露聲明的內容有不明白之處，應尋求獨立的專業意見。<br />
                            </div>
                        )}
                        </>
                    )}
                    </div>
                </div>


                {RiskCompleted && (
                    <div className="col-12 pt-3 text-start">

                        <SwitchBoxWapper>
                            <SwitchBox id="UnstandandRiskDisclosure" type="checkbox"
                                {...register("UnstandandRiskDisclosure")} />
                            <SwitchBoxLabel htmlFor="UnstandandRiskDisclosure">
                                <span> 
                                <span className="fb-red"> * </span>
                                本人清楚明白相關投資產品的各類風險及願意承擔買賣相關投資產品所帶來的潛在風險及責任。
                                </span>
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>

                        <SwitchBoxWapper>
                            <SwitchBox id="AcceptRiskDisclosure" type="checkbox"
                                {...register("AcceptRiskDisclosure")} />
                            <SwitchBoxLabel htmlFor="AcceptRiskDisclosure">
                                <span> 
                                <span className="fb-red"> * </span>
                                本人已閱讀該風險披露聲明並沒有任何問題及無需徵求獨立的意見。
                                </span>
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>

                        <SwitchBoxWapper>
                            <SwitchBox id="PersonalInfoCollection" type="checkbox"
                                {...register("PersonalInfoCollection")} />
                            <SwitchBoxLabel htmlFor="PersonalInfoCollection">
                                <span> 
                                <span className="fb-red"> * </span>
                                本人同意 個人資料收集聲明跨境個人資料轉讓 之所有內容細節、條款 及 將本人(等)的個人資料轉移至香港以外的地方。
                                </span>
                                
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>

                        <SwitchBoxWapper>
                            <SwitchBox id="AcceptDisclaimer" type="checkbox"
                                {...register("AcceptDisclaimer")} />
                            <SwitchBoxLabel htmlFor="AcceptDisclaimer">

                                <span> 
                                <span className="fb-red"> * </span>
                                本人確認本「開戶表格」以上所載所有以電子形式輸入的資料均屬完整及正確。<br />
                                本人承認、明白及同意富昌證券有限公司及／或富昌期貨有限公司（視具體情況而定）將可使用及依賴該等資料以操作本人以上的帳戶。<br />
                                本人確認本人同意本「開戶表格」以上所有所載的選擇、同意、聲明、披露、 “客戶聲明”、 “自動交換財務帳戶資料”、 “海外帳戶稅收合規法案聲明書”(如適用)、 
                                “<a href="https://media.ffg.com.hk/MediaFolder/MediaPage/CashClientAgreement.pdf" target="_blank">現金客戶協議書</a>”(如適用)、 
                                “<a href="https://media.ffg.com.hk/MediaFolder/MediaPage/MarginClientAgreement.pdf" target="_blank">保證金客戶協議書</a>”(如適用)、 
                                “<a href="https://media.ffg.com.hk/MediaFolder/MediaPage//F14.pdf" target="_blank">期貨客戶協議書</a>”(如適用)。 <br />
                                本人再承認並確認已就上述開戶表格的內容按照本人明白的語言(英文或中文)獲得充分的解釋，本人並已閱讀及同意有關以電子形式輸入的所須資料。 (本「開戶表格」之中英文本文義如有歧異，請以英文本為準。)<br />
                                </span>

                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>

                        <span className={Object.keys(errors).length > 0 ? "is-invalid" : ""}></span>
                        <div className="invalid-feedback text-center" style={{fontSize:"1em", fontWeight:"bold"}}> <i class="bi bi-exclamation-square-fill"></i> 閣下還有未完成填寫/填錯資料地方，請再重新填寫  <br></br></div>

                    </div>
                )}
            </div>

        </StepContainer>);
};

export default RiskDisclosure;