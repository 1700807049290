import React, { useState, useEffect, createContext, useContext } from 'react'

import queryString from "query-string"

import { FormattedMessage } from 'react-intl';
import StepWizard from "react-step-wizard";
import logo from './logo.svg';
import './App.css';

import { useUserSettings } from './components/UserSettingsProvider';
import Login from './components/login/Login';
import UserClassification from './components/UserClassification/UserClassification';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import SettlementInfo from './components/SettlementInfo/SettlementInfo';
import FFGAccountInfo from './components/FFGAccountInfo/FFGAccountInfo';
import RiskDisclosure from './components/RiskDisclosure/RiskDisclosure';
import FinancialInfo from './components/FinancialInfo/FinancialInfo';
import ClientSignature from './components/ClientSignature/ClientSignature';
import EmploymentInfo from './components/EmploymentInfo/EmploymentInfo';
import SelfDisclosure from './components/SelfDisclosure/SelfDisclosure';
import BioCheck from './components/BioCheck/BioCheck';
import AOStatusInfo from './components/AOStatusInfo/AOStatusInfo';
import Invite from './components/Invite/Invite';
import OpeningBooking from './components/OpeningBooking/OpeningBooking';
import styled from 'styled-components';

function App(props) {

  //const {setLocale} = this.props;

  const { locale, changeLang } = useUserSettings();
  const [wizard, setWizardInstance] = useState();
  const queryParams = queryString.parse(window.location.search);

  const referralCode = queryParams.referral_code ?? "";
  const initialStep = referralCode !== "" ? 1 : 2;

  const noTransitions = {
    enterRight: '',
    enterLeft: '',
    exitRight: '',
    exitLeft: ''
  };

  const onStepChange = (state) => {
    // console.log(state, "onStepChange");
    // if(state.activeStep === 3 && state.previousStep === 2)
    //   wizard.goToStep(1);
  };

  useEffect(() => {
    //console.log("onInitial");
  }, []);

  //const signaturePad = React.createRef();

  //const clear = () => {
  //signaturePad.current.clear();
  //console.log(signaturePad);
  //}



  return (

    <div className="App" style={{padding:0, margin:0}}>
      <StepWizard className='StepWizardStyle'  style={{padding:0, margin:0}}
        onStepChange={onStepChange}
        initialStep={initialStep}
        isHashEnabled={false}
        transitions={noTransitions}
        instance={setWizardInstance}
        isLazyMount={true}
      >

        {/* <SelfDisclosure stepName="SelfDisclosure" prevStepName="FinancialInfo" nextStepName="RiskDisclosure"></SelfDisclosure> */}
        {/* <FinancialInfo stepName="FinancialInfo" prevStepName="EmploymentInfo" nextStepName="SelfDisclosure"></FinancialInfo>  */}

        <Invite WizardInstance={wizard} stepName={"Invite"} referralCode={referralCode} />
        <Login WizardInstance={wizard} stepName={"Login"} />
        <UserClassification stepName={"IDType"} nextName={"PersonalInfo"} />
        <PersonalInfo stepName={"PersonalInfo"} prevStepName={"IDType"} nextStepName={"SettlementInfo"} />
        <SettlementInfo stepName={"SettlementInfo"} prevStepName={"PersonalInfo"} nextStepName={"FFGAccountInfo"} />
        <FFGAccountInfo stepName={"FFGAccountInfo"} prevStepName={"SettlementInfo"} nextStepName="EmploymentInfo"></FFGAccountInfo>

        <EmploymentInfo stepName="EmploymentInfo" prevStepName="FFGAccountInfo" nextStepName="FinancialInfo"></EmploymentInfo>
        <FinancialInfo stepName="FinancialInfo" prevStepName="EmploymentInfo" nextStepName="SelfDisclosure"></FinancialInfo>

        <SelfDisclosure stepName="SelfDisclosure" prevStepName="FinancialInfo" nextStepName="RiskDisclosure"></SelfDisclosure>

        <RiskDisclosure stepName="RiskDisclosure" prevStepName="SelfDisclosure" nextStepName="ClientSignature"></RiskDisclosure>
        <ClientSignature stepName="ClientSignature" ></ClientSignature>
        <BioCheck stepName="BioCheck" nextStepName="AOStatusInfo"></BioCheck>

        <AOStatusInfo stepName="AOStatusInfo"></AOStatusInfo>
        
        <OpeningBooking stepName="OpeningBooking" />
      </StepWizard>


      {/* <button type="button" onClick={()=>{ wizard.previousStep() }}> back </button>
      <button type="button" onClick={()=>{ wizard.nextStep() }}> next </button>  */}

      {/* props.nextStep */}
      {/* <button onClick={() => changeLang('zh-hk')}>繁</button>
      <button onClick={() => changeLang('zh-cn')}>簡</button> */}
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React <br />
          <FormattedMessage id="app.learn" values={{ name: 'React' }} />
        </a>
      </header>

      <div className="columns">
        <div className="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <SignaturePad options={{ minWidth: 5, maxWidth: 10, penColor: 'rgb(66, 133, 244)' }}
                  redrawOnResize={true}
                  ref={signaturePad} />
              </div>
            </div>
            <footer className="card-footer">
              <button className="button" onClick={() => clear()}>
                Clear
              </button>
              <p className="card-footer-item">
                <span>sign above</span>
              </p>
              <button className="button" onClick={() => {
                console.log(signaturePad.current.toDataURL());
                window.open(signaturePad.current.toDataURL());
              }}>
                Save
              </button>
            </footer>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default App;
