import React, { useEffect, useState } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import { FBLabel, InvalidFieldStyle, PrettyPrintJson, cssFormInput, ReqFieldIcon } from '../../services/Utils';
import { SwitchBoxWapper, SwitchBox, SwitchBoxLabel } from './SwitchBoxStyle';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { subMonths, format } from 'date-fns';
import zh from 'date-fns/locale/zh-CN';
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Rebate_9_3 } from '../Promotion/Rebate_9_3';
import { Future_0_Comission } from '../Promotion/Future_0_Comission';
import { FUT_OPT_0_Comm } from '../Promotion/FUT_OPT_0_Comm';
import { SEC_2M_0_Comm } from "../Promotion/SEC_2M_0_Comm";
import { TOTIELONG_SEC } from "../Promotion/TOTIELONG_SEC";
import { TOTIELONG_FUT } from "../Promotion/TOTIELONG_FUT";

const SelfDisclosure = (props) => {
    registerLocale('zh', zh);
    const { userRef } = useUserSettings();
    const [CountryList, setCountryList] = useState([]);
    const validationSchema = Yup.object().shape({
        IsBeneficialOwnerBool: Yup.boolean(),
        IsInstructorBool: Yup.boolean(),

        IsLicenseEmployeeBool: Yup.boolean(),
        LicenseeEmployeeName: Yup.string().nullable()
            .when('IsLicenseEmployeeBool', (val, schema) => {
                return val ? schema.required("請填上持牌或註冊人名稱") : schema.notRequired();
            }),

        IsFulbrightEmployeeBool: Yup.boolean(),
        FulbrightEmployeeName: Yup.string().nullable()
            .when('IsFulbrightEmployeeBool', (val, schema) => {
                return val ? schema.required("請填上持牌或註冊人名稱") : schema.notRequired();
            }),
        FulbrightEmployeeRelation: Yup.string().nullable()
            .when('IsFulbrightEmployeeBool', (val, schema) => {
                return val ? schema.required("請填上關係") : schema.notRequired();
            }),

        IsShareHolderBool: Yup.boolean(),
        ShareHolderCode: Yup.string().nullable()
            .when('IsShareHolderBool', (val, schema) => {
                return val ? schema.required("請填上公司名稱及上市編號") : schema.notRequired();
            }),

        IsSpouseMarginClientBool: Yup.boolean(),
        SpouseMarginClientName: Yup.string().nullable()
            .when('IsSpouseMarginClientBool', (val, schema) => {
                return val ? schema.required("請填上配偶姓名") : schema.notRequired();
            }),
        SpouseMarginClientCode: Yup.string().nullable()
            .when('IsSpouseMarginClientBool', (val, schema) => {
                return val ? schema.required("請填上帳戶號碼") : schema.notRequired();
            }),

        IsSpouseControlBool: Yup.boolean(),
        SpouseControlCompanyName: Yup.string().nullable()
            .when('IsSpouseMarginClientBool', (val, schema) => {
                return val ? schema.required("請填上公司名稱") : schema.notRequired();
            }),
        SpouseControlClientCode: Yup.string().nullable()
            .when('IsSpouseMarginClientBool', (val, schema) => {
                return val ? schema.required("請填上帳戶號碼") : schema.notRequired();
            }),

        HasCriminalRecord: Yup.boolean(),
        CriminalRecord: Yup.string().nullable()
            .when('HasCriminalRecord', (val, schema) => {
                return val ? schema.required("請註明過往有否任何刑事犯罪紀錄及/或現在是否接受任何刑事訴訟案件審訊或調查") : schema.notRequired();
            }),
        IsLicensedPerson: Yup.boolean(),
        LicensedPerson: Yup.string().nullable()
            .when('IsLicensedPerson', (val, schema) => {
                return val ? schema.required("請註明過往或現在是否持有任何專業資格牌照（如律師、會計師、醫生等）及/或受監管的牌照（如證券、地產、保險等）") : schema.notRequired();
            }),
        HasViolationRecord: Yup.boolean(),
        ViolationRecord: Yup.string().nullable()
            .when('HasViolationRecord', (val, schema) => {
                return val ? schema.required("請註明持有任何專業資格牌照 及 /或受監管牌照，本人於過往有否任何違規紀錄及 /或現在是否接受任何與其專業資格牌照及 /或受監管牌照有關的違規調查") : schema.notRequired();
            }),
        IsPolitician: Yup.boolean(),
        Politician: Yup.string().nullable()
            .when('IsPolitician', (val, schema) => {
                return val ? schema.required("請註明過往或現在是否政治人物或與任何政治人物有聯繫或關係") : schema.notRequired();
            }),

        BeneficialName: Yup.string().nullable()
            .when('IsBeneficialOwnerBool', (val, schema) => {
                return !val ? schema.required("請填上最終權益擁有人的姓名") : schema.notRequired();
            }),
        BeneficialBirthday: Yup.date().nullable()
            .when('IsBeneficialOwnerBool', (val, schema) => {
                return !val ? schema.required("請填上最終權益擁有人的出生日期") : schema.notRequired();
            }),
        BeneficialHKID: Yup.string().nullable()
            .when('IsBeneficialOwnerBool', (val, schema) => {
                return !val ? schema.required("請填上最終權益擁有人的護照/身份證號碼") : schema.notRequired();
            }),
        BeneficialNation: Yup.string().nullable()
            .when('IsBeneficialOwnerBool', (val, schema) => {
                return !val ? schema.required("請填上最終權益擁有人的國籍") : schema.notRequired();
            }),
        BeneficialAddress: Yup.string().nullable()
            .when('IsBeneficialOwnerBool', (val, schema) => {
                return !val ? schema.required("請填上最終權益擁有人的地址") : schema.notRequired();
            }),

        InstructorName: Yup.string().nullable()
            .when('IsInstructorBool', (val, schema) => {
                return !val ? schema.required("請填上最終交易指示發出者的姓名") : schema.notRequired();
            }),
        InstructorBirthday: Yup.date().nullable()
            .when('IsInstructorBool', (val, schema) => {
                return !val ? schema.required("請填上最終交易指示發出者的出生日期") : schema.notRequired();
            }),
        InstructorHKID: Yup.string().nullable()
            .when('IsInstructorBool', (val, schema) => {
                return !val ? schema.required("請填上最終交易指示發出者的護照/身份證號碼") : schema.notRequired();
            }),
        InstructorNation: Yup.string().nullable()
            .when('IsInstructorBool', (val, schema) => {
                return !val ? schema.required("請填上最終交易指示發出者的國籍") : schema.notRequired();
            }),
        InstructorAddress: Yup.string().nullable()
            .when('IsInstructorBool', (val, schema) => {
                return !val ? schema.required("請填上最終交易指示發出者的地址") : schema.notRequired();
            }),
    });

    const { register, trigger, watch, getValues, setValue, handleSubmit, reset, control,
        formState: { isValid, errors }, clearErrors } = useForm({
            mode: "all",
            shouldFocusError: true,
            resolver: yupResolver(validationSchema),
            defaultValues: {
                IsBeneficialOwnerBool: true,
                IsInstructorBool: true,
            }
        });

    const watchAllFields = watch();

    useEffect(() => {
        AOSServices.GetCityList()
            .then((resp) => {
                if (resp) {
                    setCountryList(resp);
                }
            });
    }, []);

    useEffect(() => {
        AOSServices.GetSelfDisclosureInfo(userRef.RefCode)
            .then((resp) => {
                if (resp && resp.Result) {
                    reset(resp.Info);
                }
            });
    }, [reset]);

    const goNext = () => {
        //console.log(isValid, "isValid");
        if (!isValid) {
            trigger();
            return new Promise(resolve => resolve(false));
        } else {
            const data = getValues();
            return new Promise(resolve => {
                AOSServices.SaveSelfDisclosureInfo(userRef.RefCode, data, "RiskDisclosure")
                    .then(res => {
                        if (!res || !res.Result) {
                            alert("提交資料失敗，請重新再試");
                        }
                        resolve(res.Result);
                    });
            });
        }
    };

    const cssFormLabelCol = "col-md-2 col-xs-12 col-form-label my-0 my-md-2";
    const cssFormInputCol = "col-md-4 col-xs-12 my-0 my-md-2";

    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            {...props}>
            <div className="ffg-form-narrow">
                <div className="row text-start">
                    <div className="col-12">
                        <h3 className="pt-3"> 最終權益擁有人及戶口交易指示發出者 </h3>
                    </div>

                    <div className="col-11 offset-1">

                        <SwitchBoxWapper>
                            <SwitchBox id="IsBeneficialOwnerBool" type="checkbox" {...register("IsBeneficialOwnerBool")} />
                            <SwitchBoxLabel htmlFor="IsBeneficialOwnerBool">
                                本人是此戶口最終權益擁有人
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {!watchAllFields.IsBeneficialOwnerBool && (
                            <div className="col-12">
                                <div className="row card-body bg-danger bg-opacity-10">
                                    <h6>此賬戶最終權益擁有人的資料如下:</h6>
                                    <FBLabel className={cssFormLabelCol} text="姓名" required={1} />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("BeneficialName")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.BeneficialName)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.BeneficialName?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="出生日期" required={1} />
                                    <div className={cssFormInputCol}>
                                        <Controller
                                            name="BeneficialBirthday"
                                            control={control}
                                            render={({ field }) => (
                                                <ReactDatePicker
                                                    className={`form-control ${InvalidFieldStyle(errors?.BeneficialBirthday)}`}
                                                    placeholderText=""
                                                    dateFormat="yyyy-MM-dd"
                                                    locale="zh"
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={60}
                                                    withPortal
                                                    maxDate={subMonths(new Date(), 18 * 12)}
                                                    onChange={(e) => field.onChange(e)}
                                                    selected={field.value}
                                                />
                                            )}
                                        />
                                        <div className="invalid-feedback"> {errors?.BeneficialBirthday?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="護照/身份證號碼" required={1} />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("BeneficialHKID")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.BeneficialHKID)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.BeneficialHKID?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="國籍" required={1} />
                                    <div className={cssFormInputCol}>
                                        <select
                                            {...register("BeneficialNation")}
                                            className={`form-select ${InvalidFieldStyle(errors?.BeneficialNation)}`}
                                        >
                                            <option></option>
                                            {
                                                CountryList && CountryList.map((m, idx) => {
                                                    return (<option key={idx} value={m.Code}> {m.Name} </option>)
                                                })
                                            }
                                        </select>
                                        <div className="invalid-feedback"> {errors?.BeneficialNation?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="地址" required={1} />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("BeneficialAddress")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.BeneficialAddress)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.BeneficialAddress?.message} </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <SwitchBoxWapper>
                            <SwitchBox id="IsInstructorBool" type="checkbox" {...register("IsInstructorBool")} />
                            <SwitchBoxLabel htmlFor="IsInstructorBool">
                                本人是此戶口最終交易指示發出者
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {!watchAllFields.IsInstructorBool && (
                            <div className="col-12">
                                <div className="row card-body bg-danger bg-opacity-10">
                                    <h6>此賬戶最終交易指示發出者的資料如下:</h6>
                                    <FBLabel className={cssFormLabelCol} text="姓名" required={1} />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("InstructorName")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.InstructorName)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.InstructorName?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="出生日期" required={1} />
                                    <div className={cssFormInputCol}>
                                        <Controller
                                            name="InstructorBirthday"
                                            control={control}
                                            render={({ field }) => (
                                                <ReactDatePicker
                                                    className={`form-control ${InvalidFieldStyle(errors?.InstructorBirthday)}`}
                                                    placeholderText=""
                                                    dateFormat="yyyy-MM-dd"
                                                    locale="zh"
                                                    showYearDropdown
                                                    scrollableYearDropdown
                                                    yearDropdownItemNumber={60}
                                                    withPortal
                                                    maxDate={subMonths(new Date(), 18 * 12)}
                                                    onChange={(e) => field.onChange(e)}
                                                    selected={field.value}
                                                />
                                            )}
                                        />
                                        <div className="invalid-feedback"> {errors?.InstructorBirthday?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="護照/身份證號碼" required={1} />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("InstructorHKID")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.InstructorHKID)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.InstructorHKID?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="國籍" required={1} />
                                    <div className={cssFormInputCol}>
                                        <select
                                            {...register("InstructorNation")}
                                            className={`form-select ${InvalidFieldStyle(errors?.InstructorNation)}`}
                                        >
                                            <option></option>
                                            {
                                                CountryList && CountryList.map((m, idx) => {
                                                    return (<option key={idx} value={m.Code}> {m.Name} </option>)
                                                })
                                            }
                                        </select>
                                        <div className="invalid-feedback"> {errors?.InstructorNation?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="地址" required={1} />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("InstructorAddress")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.InstructorAddress)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.InstructorAddress?.message} </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                    <div className="col-12">
                        <h3 className="pt-3"> 披露（如“否”，以下選項可不勾選）</h3>
                    </div>

                    {/* <div className="col-12">
                    <h3 className="pt-2 px-0 fb-red"> 披露 </h3>
                </div> */}

                    <div className="col-11 offset-1">
                        <SwitchBoxWapper>
                            <SwitchBox id="IsLicenseEmployeeBool" type="checkbox" {...register("IsLicenseEmployeeBool")} />
                            <SwitchBoxLabel htmlFor="IsLicenseEmployeeBool">
                                1. 本人是香港證監會持牌人或註冊人之董事或僱員？如"是"，請註明
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.IsLicenseEmployeeBool && (
                            <div className="col-12">
                                <div className="card-body bg-danger bg-opacity-10">
                                    <FBLabel text="持牌或註冊人名稱" required={1} />
                                    <input
                                        {...register("LicenseeEmployeeName")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.LicenseeEmployeeName)}`}
                                    />
                                    <div className="invalid-feedback"> {errors?.LicenseeEmployeeName?.message} </div>
                                </div>
                            </div>
                        )}

                        <SwitchBoxWapper>
                            <SwitchBox id="IsFulbrightEmployeeBool" type="checkbox" {...register("IsFulbrightEmployeeBool")} />
                            <SwitchBoxLabel htmlFor="IsFulbrightEmployeeBool">
                                2. 本人是富昌證券或富昌期貨的僱員／營業經紀或該人士的親屬？如"是"，請註明
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.IsFulbrightEmployeeBool && (
                            <div className="row card-body bg-danger bg-opacity-10">
                                <div className="col-sm-6 col-xs-12">
                                    <FBLabel text="持牌或註冊人名稱" required={1} />
                                    <input
                                        {...register("FulbrightEmployeeName")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.FulbrightEmployeeName)}`}
                                    />
                                    <div className="invalid-feedback"> {errors?.FulbrightEmployeeName?.message} </div>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <FBLabel text="關係" required={1} />
                                    <input
                                        {...register("FulbrightEmployeeRelation")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.FulbrightEmployeeRelation)}`}
                                    />
                                    <div className="invalid-feedback"> {errors?.FulbrightEmployeeRelation?.message} </div>
                                </div>
                            </div>
                        )}

                        <SwitchBoxWapper>
                            <SwitchBox id="IsShareHolderBool" type="checkbox" {...register("IsShareHolderBool")} />
                            <SwitchBoxLabel htmlFor="IsShareHolderBool">
                                3. 本人是任何其股份在交易所或市場買賣的(上市)公司之主要股東#、高級人員或董事？ (只適用於證券帳戶及期貨帳戶)
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.IsShareHolderBool && (
                            <div className="row card-body bg-danger bg-opacity-10">
                                <FBLabel text="公司名稱及上市編號 * #「主要股東」指，就一間公司而言，任何人士其於該公司之任何股東大會有權行使，或可控制行使，10%或以上之投票權" required={1} />
                                <input
                                    {...register("ShareHolderCode")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.ShareHolderCode)}`}
                                />
                                <div className="invalid-feedback"> {errors?.ShareHolderCode?.message} </div>
                            </div>
                        )}

                        <SwitchBoxWapper>
                            <SwitchBox id="IsSpouseMarginClientBool" type="checkbox" {...register("IsSpouseMarginClientBool")} />
                            <SwitchBoxLabel htmlFor="IsSpouseMarginClientBool">
                                4. 本人的配偶是富昌證券的證券保證金客戶？
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.IsSpouseMarginClientBool && (
                            <div className="row card-body bg-danger bg-opacity-10">
                                <div className="col-sm-6 col-xs-12">
                                    <FBLabel text="配偶姓名" required={1} />
                                    <input
                                        {...register("SpouseMarginClientName")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.SpouseMarginClientName)}`}
                                    />
                                    <div className="invalid-feedback"> {errors?.SpouseMarginClientName?.message} </div>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <FBLabel text="帳戶號碼" required={1} />
                                    <input
                                        {...register("SpouseMarginClientCode")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.SpouseMarginClientCode)}`}
                                    />
                                    <div className="invalid-feedback"> {errors?.SpouseMarginClientCode?.message} </div>
                                </div>
                            </div>
                        )}

                        <SwitchBoxWapper>
                            <SwitchBox id="IsSpouseControlBool" type="checkbox" {...register("IsSpouseControlBool")} />
                            <SwitchBoxLabel htmlFor="IsSpouseControlBool">
                                5. 本人是單獨或與配偶共同控制富昌證券的任何證券保證金公司客戶35%或以上的表決權？
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.IsSpouseControlBool && (
                            <div className="row card-body bg-danger bg-opacity-10">
                                <div className="col-sm-6 col-xs-12">
                                    <FBLabel text="公司名稱" required={1} />
                                    <input
                                        {...register("SpouseControlCompanyName")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.SpouseControlCompanyName)}`}
                                    />
                                    <div className="invalid-feedback"> {errors?.SpouseControlCompanyName?.message} </div>
                                </div>
                                <div className="col-sm-6 col-xs-12">
                                    <FBLabel text="帳戶號碼" required={1} />
                                    <input
                                        {...register("SpouseControlClientCode")}
                                        type="text"
                                        className={`form-control ${InvalidFieldStyle(errors?.SpouseControlClientCode)}`}
                                    />
                                    <div className="invalid-feedback"> {errors?.SpouseControlClientCode?.message} </div>
                                </div>
                            </div>
                        )}


                        <hr className="dot-line" />

                        <SwitchBoxWapper>
                            <SwitchBox id="HasCriminalRecord" type="checkbox" {...register("HasCriminalRecord")} />
                            <SwitchBoxLabel htmlFor="HasCriminalRecord">
                                本人於過往有否任何刑事犯罪紀錄及/或現在是否接受任何刑事訴訟案件審訊或調查？
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.HasCriminalRecord && (
                            <div className="card-body bg-danger bg-opacity-10">
                                <FBLabel text="請註明:" required={1} />
                                <input
                                    {...register("CriminalRecord")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.CriminalRecord)}`}
                                />
                                <div className="invalid-feedback"> {errors?.CriminalRecord?.message} </div>
                            </div>
                        )}


                        <SwitchBoxWapper>
                            <SwitchBox id="IsLicensedPerson" type="checkbox" {...register("IsLicensedPerson")} />
                            <SwitchBoxLabel htmlFor="IsLicensedPerson">
                                本人於過往或現在是否持有任何專業資格牌照（如律師、會計師、醫生等）及/或受監管的牌照（如證券、地產、保險等）？
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.IsLicensedPerson && (
                            <div className="card-body bg-danger bg-opacity-10">
                                <FBLabel text="請註明:" required={1} />
                                <input
                                    {...register("LicensedPerson")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.LicensedPerson)}`}
                                />
                                <div className="invalid-feedback"> {errors?.LicensedPerson?.message} </div>
                            </div>
                        )}

                        <SwitchBoxWapper>
                            <SwitchBox id="HasViolationRecord" type="checkbox" {...register("HasViolationRecord")} />
                            <SwitchBoxLabel htmlFor="HasViolationRecord">
                                本人持有任何專業資格牌照 及 /或受監管牌照，本人於過往有否任何違規紀錄及 /或現在是否接受任何與其專業資格牌照及 /或受監管牌照有關的違規調查？
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.HasViolationRecord && (
                            <div className="card-body bg-danger bg-opacity-10">
                                <FBLabel text="請註明:" required={1} />
                                <input
                                    {...register("ViolationRecord")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.ViolationRecord)}`}
                                />
                                <div className="invalid-feedback"> {errors?.ViolationRecord?.message} </div>
                            </div>
                        )}

                        <SwitchBoxWapper>
                            <SwitchBox id="IsPolitician" type="checkbox" {...register("IsPolitician")} />
                            <SwitchBoxLabel htmlFor="IsPolitician">
                                本人於過往或現在是否政治人物或與任何政治人物有聯繫或關係# ?
                                <p className="subscript mb-0">
                                    (註：政治人物指在任何國家 /省 /州 /城市擔任或曾擔任重要公職的個人，包括國家元首、政府首長、資深從政者、高級政府、司法或軍事官員、國有企業高級行政人員及重要政黨幹事。)
                                </p>
                                <span className="checkmark"></span>
                            </SwitchBoxLabel>
                        </SwitchBoxWapper>
                        {watchAllFields.IsPolitician && (
                            <div className="card-body bg-danger bg-opacity-10">
                                <FBLabel text="請註明:" required={1} />
                                <input
                                    {...register("Politician")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.Politician)}`}
                                />
                                <div className="invalid-feedback"> {errors?.Politician?.message} </div>
                            </div>
                        )}

                        <div className="card my-3">
                            <div className="card-body bg-warning bg-opacity-10">
                                #聯繫或關係指<br />
                                a. 為政治人物的配偶、伴侶、子女或父母，或 為 政治人物子女的配偶或伴侶；<br />
                                b. 為政治人物擁有實益控制權的法人 (即公司或企業 )；或<br />
                                c. 為與政治人物有密切業務關係的個人、法人 (即公司或企業 )或信託的實益擁有人的個人；或<br />
                                d. 替政治人物持有法人 (即公司或企業 )或信託的個人，而有關法人是為政治人物的利益而成立的。<br />
                            </div>
                        </div>

                    </div>
                    
                    {userRef.PromoCode?.includes("9+3-REBATE") && userRef.FBAcc?.some(w => w === "S") && (<div><Rebate_9_3></Rebate_9_3></div>)}

                    {userRef.PromoCode?.includes("FUT-0-COMM") && userRef.FBAcc?.includes("F") && (<div className="mt-3"><Future_0_Comission></Future_0_Comission></div>)} 
                    
                    {userRef.PromoCode?.includes("FUT-OPT-0-COMM") && userRef.FBAcc?.includes("F") && (<div className="mt-3"><FUT_OPT_0_Comm></FUT_OPT_0_Comm></div>)} 

                    {userRef.PromoCode?.includes("SEC-2M-0-COMM") && userRef.FBAcc?.includes("S") && (<div className="mt-3"><SEC_2M_0_Comm></SEC_2M_0_Comm></div>)} 

                    {userRef.PromoCode?.includes("TOTIELONG") && userRef.FBAcc?.includes("S") && (<div className="mt-3"><TOTIELONG_SEC></TOTIELONG_SEC></div>)} 

                    {userRef.PromoCode?.includes("TOTIELONG") && userRef.FBAcc?.includes("F") && (<div className="mt-3"><TOTIELONG_FUT></TOTIELONG_FUT></div>)} 
                </div>
            </div>

        </StepContainer>
    );
};

export default SelfDisclosure;