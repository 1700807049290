import React, { Component } from "react";
import AOSServices from "../../services/AOSServices";
import { PreviewImgWrapper, PreviewImg, DocUploadButton } from "./DocUploaderStyle";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faSquare, faTrashAlt, faPlusCircle, faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import Compress from "compress.js";

export default class DocUploader extends Component {
    constructor(props) {
        super(props);

        this.selectFiles = this.selectFiles.bind(this);
        this.upload = this.upload.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
        this.SelectFilesRef = this.SelectFilesRef.bind(this);
        this.DeleteFile = this.DeleteFile.bind(this);
        this.inputFilesRef = React.createRef();
        this.compress = new Compress();

        library.add(faCircle, faSquare, faTrashAlt, faSpinner, faCheckCircle, faTimesCircle);

        this.state = {
            selectedFiles: undefined,
            previewImages: [],
            progressInfos: [],
            message: [],

            imageInfos: [],
            processCount: 0
        };
    }

    componentDidMount() {
        AOSServices.GetDocList(this.props.RefCode, this.props.AType).then((response) => {
            this.setState({
                imageInfos: response,
            });
        });
    }

    selectFiles(event) {
        const files = [...event.target.files]
        let images = this.state.previewImages ?? [];
        let resizedImgFiles = this.state.selectedFiles ?? [];

        this.compress.compress(files, {
            size: 1, // the max size in MB, defaults to 2MB
            quality: .90, // the quality of the image, max is 1,
            maxWidth: 3840, // the max width of the output image, defaults to 1920px
            maxHeight: 3840, // the max height of the output image, defaults to 1920px
            resize: true, // defaults to true, set false if you do not want to resize the image width and height
            rotate: false, // See the rotation section below
        }).then((data) => {
            // returns an array of compressed images

            for (let i = 0; i < data.length; i++) {
                const img = data[i];
                const base64str = img.data;
                const imgExt = img.ext;
                const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
                resizedFile.name = resizedFile.fileName = img.alt;

                resizedImgFiles.push(resizedFile);
                images.push(URL.createObjectURL(resizedFile))
            }

            const { progressInfos, message } = this.state;
            this.setState({
                progressInfos: progressInfos,
                message: message,
                selectedFiles: resizedImgFiles,
                previewImages: images
            });
        }).then(() => {
            // upload all files
            this.uploadImages();
        });
    }

    upload(idx, file) {
        let _progressInfos = [...this.state.progressInfos];

        this.setState((p) => { return { processCount: p.processCount + 1 } });
        AOSServices.UploadDoc(this.props.RefCode, this.props.AType, file, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                progressInfos: _progressInfos,
            })
        })
            .then((r) => {
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Uploaded the image successfully: " + file.fileName];
                    // let _selectedFiles = prev.selectedFiles;
                    // let removeIdx = _selectedFiles.indexOf(w => w.fileName === file.fileName);
                    // if (removeIdx >= 0) {
                    //     _selectedFiles = _selectedFiles.splic(removeIdx, 1);
                    //     console.log("remove");
                    // }

                    return {
                        message: nextMessage,
                        //selectedFiles: _selectedFiles
                        processCount: prev.processCount - 1
                    };
                });

                if (r && !r.Result) {
                    throw 'Could not upload the image';
                }

                //return AOSServices.GetDocList(this.props.RefCode, this.props.AType);
            })
            .then(() => {
                let pc = this.state.processCount;
                if (pc === 0) {
                    this.setState((prev) => {
                        return {
                            selectedFiles: [],
                            previewImages: [],
                        };
                    });

                    AOSServices.GetDocList(this.props.RefCode, this.props.AType)
                        .then((files) => {
                            this.setState({
                                imageInfos: files,
                            });
                        });
                }
            })
            // .then((files) => {
            //     this.setState({
            //         imageInfos: files,
            //     });
            // })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Could not upload the image: " + file.fileName];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage
                    };
                });
            });
    };

    DeleteFile = (DocID) => {
        AOSServices.DeleteDoc(this.props.RefCode, DocID)
            .then((r) => {
                if (r && r.Result) {
                    AOSServices.GetDocList(this.props.RefCode, this.props.AType)
                        .then((files) => {
                            this.setState({
                                imageInfos: files,
                            });
                        });

                }
            });
    };

    SelectFilesRef = (event) => {
        this.inputFilesRef.current.click();
    };

    uploadImages() {
        const selectedFiles = this.state.selectedFiles;
        let _progressInfos = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].fileName });
        }

        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i]);
                }
            }
        );
    }

    render() {
        const { selectedFiles, previewImages, progressInfos, message, imageInfos } = this.state;

        return (
            <div>
                {/* <div className="row">
                    <div className="col-8">
                        <label className="btn btn-default p-0">
                             <input type="file" multiple accept="image/*" onChange={this.selectFiles} /> 
                        </label>
                    </div>

                    <div className="col-4">
                        <button
                            className="btn btn-success btn-sm"
                            disabled={!selectedFiles}
                            onClick={this.uploadImages}
                        >
                            Upload
                        </button>
                    </div>
                </div> */}

                {/* {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progressInfo.percentage + "%" }}
                                >
                                    {progressInfo.percentage}%
                                </div>
                            </div>
                        </div>
                    ))} */}
                {/* {this.state.processCount},
                {this.state.selectedFiles?.length} */}
                {previewImages && (
                    <div className="d-flex flex-wrap">

                        <PreviewImgWrapper className="p-1">
                            <DocUploadButton onClick={this.SelectFilesRef} disabled={this.state.processCount > 0}>
                                <span className="fa-layers fa-fw fa-2x m-auto d-flex h-100">
                                    <FontAwesomeIcon icon={faPlusCircle}
                                        color={this.state.processCount > 0 ? "rgb(240, 200, 200)" : "rgb(204, 0, 16)"}
                                        transform="shrink-1" />
                                </span>
                                <input disabled={this.state.processCount > 0} type="file" ref={this.inputFilesRef} multiple accept="image/*" onChange={this.selectFiles} />
                            </DocUploadButton>
                        </PreviewImgWrapper>

                        {previewImages.map((img, i) => {
                            return (
                                <PreviewImgWrapper className="p-1" key={`PW-${i}`}>
                                    <PreviewImg src={img} alt={"image-" + i} width={80}></PreviewImg>

                                    {progressInfos[i] && (
                                        <div className="progress">
                                            <div
                                                className="progress-bar progress-bar-info"
                                                role="progressbar"
                                                aria-valuenow={progressInfos[i].percentage}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: progressInfos[i].percentage + "%" }}
                                            >
                                                {progressInfos[i].percentage}%
                                            </div>
                                        </div>
                                    )}
                                </PreviewImgWrapper>
                            )
                        })}

                        {imageInfos &&
                            imageInfos.map((img, index) => (
                                <PreviewImgWrapper className="p-1" key={`DOC-${index}`}>
                                    <PreviewImg src={`/doc/${this.props.RefCode}/${img.ID}`} alt={"image-" + img.ID} width={80}></PreviewImg>

                                    <span className="fa-layers fa-fw fa-lg Trash d-flex" onClick={() => this.DeleteFile(img.ID)}>
                                        <FontAwesomeIcon icon="circle" color="rgb(40, 63, 123)" />
                                        <FontAwesomeIcon icon={faTrashAlt} inverse transform="shrink-6" />
                                    </span>
                                </PreviewImgWrapper>
                            ))}
                    </div>
                )}

                {/* {message.length > 0 && (
                    <div className="alert alert-secondary mt-2" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )} */}


                {/* <div className="card mt-3">
                    <div className="card-header">List of Files</div>
                    <ul className="list-group list-group-flush">
                        {imageInfos &&
                            imageInfos.map((img, index) => (
                                <li className="list-group-item" key={index}>
                                    <img src={`/doc/${this.props.RefCode}/${img.ID}`} alt={img.ID} />
                                </li>
                            ))}
                    </ul>
                </div> */}

            </div>
        );
    }
}