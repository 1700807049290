import styled, { css } from 'styled-components'

export const SwitchBoxWapper = styled.div`
display: flex;
position: relative;
padding: 4px;
margin: 0px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

& .checkmark {
    position: absolute;
    top: 5px;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(191, 119, 124);
    border-radius: 50%;
}

& input:enabled ~ label {
    color: rgb(0,0,0);
}

&:hover input:enabled ~ label {
    color: rgb(204,0,16);
}

& .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 1px;
    width: 7px;
    height: 15px;
    border: solid rgb(255,255,255);
    border-width: 0px 3px 4px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const SwitchBoxLabel = styled.label`
display: inline-grid;
width: 100%;
cursor: pointer;
padding-left: 28px !important;
color: rgb(0,0,0);
`;

export const SwitchBox = styled.input`
position: absolute;
opacity: 0;
cursor: pointer;
height: 0;
width: 0;

&:checked ~ label {
    color: rgb(0,0,0);
}

&:checked ~ label > .checkmark {
    background-color: rgb(204,0,16);
}

&:checked ~ label > .checkmark:after {
    display: block;
}

&:disabled ~ label {
    color: rgb(160,160,160);
    cursor: not-allowed;
}
`;