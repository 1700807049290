import React from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import VideoInput from "./VideoInput";

const BioCheck = (props) => {
    const { userRef } = useUserSettings();
    //const [Uploading, setUploading] = React.useState(false);
    const [VideoFile, setVideoFile] = React.useState(null);

    const handleVideoFileChanged = (v) => {
        //console.log(v);
        setVideoFile(v);
    };

    const goNext = () => {
        if (VideoFile) {
            /*
            const zip = require('jszip')();
            console.log(`videofilename:`+VideoFile.name);
            zip.file(VideoFile.name, VideoFile);

            zip.generateAsync({ type: "blob" }).then(content => {
            */
                return new Promise(resolve => {
                    AOSServices.UploadVideo(userRef.RefCode, "AOStatusInfo", VideoFile)
                        .then(r => {
                            if (!r || !r.Result) {
                                alert(r.Msg);
                            }
                            return resolve(r.Result);

                        });
                });
           // });

        }
    };



    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            {...props}>

            <div className="row">
                <div className="col-12">
                    <h3 className="pt-3"> CA認證檢測 </h3>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body text-start">
                            通過一段自拍人臉視頻(片長約3秒)進行公安實名認證。
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <VideoInput onFileChanged={handleVideoFileChanged}></VideoInput>
                </div>

                <div className="col-lg-8 offset-lg-1">

                    <p className="text-start my-3 mx-lg-5 mx-1">
                        1. 接下來將錄製視頻以驗證身份; <br />
                        2. 請保持攝像頭清晰，光線充足，臉部完整入鏡且清晰可見; <br />
                        3. 視頻錄製後，若不滿意可以重新錄製。<br />
                        4. 建議摘掉眼鏡，以提高驗證通過率。<br />
                        5. 錄製 1-3 秒視頻，及影像檔不能大於 10mb。<br />
                    </p>

                </div>
            </div>

        </StepContainer>
    );
};

export default BioCheck;