import React, { useState, useEffect, useCallback } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import AOSServices from "../../services/AOSServices";
import { FBLabel, InvalidFieldStyle, PrettyPrintJson, GetIDCardOriginName } from '../../services/Utils';
import { RadioButtonList } from "../FFGCheckbox/FFGCheckbox";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Select, { components, createFilter } from 'react-select';


const SettlementInfo = (props) => {
    const { userRef, setUserRef } = useUserSettings();
    const [BankList, setBankList] = useState([]);
    const [CommonBankList, setCommonBankList] = useState([]);

    const validationSchema = Yup.object().shape({

        Email: Yup.string().nullable().email("你的電子郵箱格式不正確").required("請輸入你的電子郵箱"),
        HasHKGBankAcc: Yup.boolean().nullable(),
        UnionPayCardNo: Yup.string().nullable()
            .when('HasHKGBankAcc', (HasHKGBankAcc, schema) => {
                return (userRef.IDCardOrigin === "CHN" && !HasHKGBankAcc) ?
                    schema.nullable().required("請輸入國內銀行卡號") : schema.notRequired();
            }),
        UnionPayMobileNo: Yup.string().nullable()
            .when('HasHKGBankAcc', (HasHKGBankAcc, schema) => {
                return (userRef.IDCardOrigin === "CHN" && !HasHKGBankAcc) ?
                    schema.required("請輸入國內銀行卡綁定的手機號碼") : schema.notRequired();
            }),
        MobileCountry: Yup.string().nullable(),
        Mobile: Yup.string().nullable(),
        PhoneCountry: Yup.string().nullable(),
        Phone: Yup.string().nullable(),
        Statement: Yup.string().nullable().oneOf(["E", "M"], "請選擇收取結單方式"),
        BankCode: Yup.string().nullable()
            .when('HasHKGBankAcc', (HasHKGBankAcc, schema) => {
                return userRef.IDCardOrigin !== "CHN" || (userRef.IDCardOrigin === "CHN" && HasHKGBankAcc) ?
                    schema.required("請輸入香港銀行") : schema.notRequired();
            }),
        BankName: Yup.string().nullable(),
        BankNumber: Yup.string().nullable()
            .when('HasHKGBankAcc', (HasHKGBankAcc, schema) => {
                return userRef.IDCardOrigin !== "CHN" || (userRef.IDCardOrigin === "CHN" && HasHKGBankAcc) ?
                    schema.required("請輸入香港銀行賬戶") : schema.notRequired();
            }),
        AuthorizeEDDA: Yup.boolean().nullable()
            .when('HasHKGBankAcc', (HasHKGBankAcc, schema) => {
                return userRef.IDCardOrigin !== "CHN" || (userRef.IDCardOrigin === "CHN" && HasHKGBankAcc) ?
                    schema.required("請選擇是否授權於帳戶建立後自動綁定EDDA") : schema.notRequired();
            }),
        AuthorizeEDDAAmount: Yup.number().positive("授權金額格式不正確").integer("授權金額格式不正確").nullable()
            .when('AuthorizeEDDA', (AuthorizeEDDA, schema) => {
                return AuthorizeEDDA ? schema.required("請輸入授權金額") : schema.notRequired();
            }),
    });

    const { register, trigger, watch, getValues, setValue, handleSubmit, reset, control, formState: { isValid, errors }, clearErrors } = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            HasHKGBankAcc: true
        }
    });

    const watchAllFields = watch();

    useEffect(() => {
        AOSServices.GetSettlementInfo(userRef.RefCode)
            .then((resp) => {
                if (resp && resp.Result) {
                    //if(userRef.IPRegion === "CN")
                    reset(resp.Info);
                }
            });
    }, [reset]);

    useEffect(() => {
        AOSServices.GetBankList().then(resp => {
            if (resp) {
                let banks = resp.map(m => {
                    return {
                        value: m.BankCode,
                        label: `(${m.BankCode}) ${m.CName || m.EName}`,
                        CName: m.CName,
                        EName: m.EName,
                    }
                });
                setBankList(banks);
                setCommonBankList(banks.filter((x) => ["003","004","012","024","072"].includes(x.value)));
            }
        });
    }, []);

    //const PropIsExist = (obj, PropName) => PropName && PropName.split('.').reduce((a, b) => (a || {})[b], obj) !== undefined;

    // const ErrorMsg = ({ PropName }) => {
    //     //console.log(PropIsExist(errors, PropName), "PropIsExist");
    //     if (errors && PropIsExist(errors, PropName)) {
    //         let msg = eval(`errors.${PropName}`);
    //         //console.log(msg, "msg");
    //         return (<div className="invalid-feedback"> {msg.message} </div>);
    //     } else
    //         return (<></>);
    // };

    // const cssFormInput = (ErrProp) => {
    //     let Props = ErrProp.split('.');
    //     let PropIsExist = Props.reduce((a, b) => (a || {})[b], errors) !== undefined;
    //     return 'form-control' + (PropIsExist ? ' is-invalid' : '');
    // };

    // const UnionPayIdentification = () => {
    //     return (
    //         <>
    //             <div className="col-12">
    //                 <h5 className="pt-2 px-0 fb-red"> 內地銀行卡認證 </h5>
    //                 <span className="float-start"> 該銀行卡僅用於實名認證，與出入資金無關。 </span>
    //             </div>

    //             <FBLabel className={cssFormLabelCol} text="銀行卡號:" required />
    //             <div className={cssFormInputCol}>
    //                 <input
    //                     {...register("UnionPayCardNo")}
    //                     type="text"
    //                     className={`form-control ${InvalidFieldStyle(errors?.UnionPayCardNo)}`}
    //                 />
    //                 <div className="invalid-feedback"> {errors?.UnionPayCardNo?.message} </div>
    //             </div>

    //             <FBLabel className={cssFormLabelCol} text="手機號碼:" required />
    //             <div className={cssFormInputCol}>
    //                 <input
    //                     {...register("UnionPayMobileNo")}
    //                     type="text"
    //                     className={`form-control ${InvalidFieldStyle(errors?.UnionPayMobileNo)}`}
    //                 />
    //                 <div className="invalid-feedback"> {errors?.UnionPayCardNo?.message} </div>
    //             </div>
    //         </>
    //     );
    // }

    const cssFormLabelCol = "col-sm-3 col-xs-12 col-form-label my-0 my-md-2";
    const cssFormInputCol = "col-sm-9 col-xs-12 my-0 my-md-2";

    const goNext = () => {
        if (!isValid) {
            //trigger("Info", { shouldFocus: true });
            trigger(undefined, { shouldFocus: true });
            return new Promise(resolve => resolve(false));
        } else {
            return new Promise(resolve => {
                const data = getValues();

                AOSServices.SaveSettlementInfo(userRef.RefCode, data, "FFGAccountInfo")
                    .then(res => {
                        if (!res || !res.Result) {
                            if (res.ErrorCode === -1)
                                alert("內地銀行卡驗證失敗，請檢查姓名﹑身份證號碼﹑銀行卡號碼﹑手機號碼是否正確。");
                            else
                                alert("提交資料失敗，請重新再試");
                        } else {
                            //console.log(`watchallfields.hashkbankacc` + watchAllFields.HasHKGBankAcc);
                            //console.log(`hashkgbankacc:` + data.HasHKGBankAcc)
                            setUserRef({ ...userRef, HasHKGBankAcc: watchAllFields.HasHKGBankAcc})
                        }
                        resolve(res.Result);
                    });
            });
        }
    };


    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            {...props}>

            {/* <PrettyPrintJson data={watchAllFields} /> */}
            {/* <PrettyPrintJson data={userRef} /> */}

            <>
                <div className="row">
                    <div className="col-12">
                        <h3 className="pt-3"> 信息驗證 ({GetIDCardOriginName(userRef.IDCardOrigin)})</h3>
                    </div>

                    <div className="col-12">
                        <h5 className="pt-2 px-0 fb-red"> 聯繫方式 </h5>
                    </div>
                </div>

                <form className="row ffg-form">

                    <FBLabel className={cssFormLabelCol} text="電子郵箱:" required />
                    <div className={cssFormInputCol}>
                        <input {...register("Email")}
                            type="email"
                            className={`form-control ${InvalidFieldStyle(errors?.Email)}`}
                        />
                        <div className="invalid-feedback"> {errors?.Email?.message} </div>
                    </div>

                    <FBLabel className={cssFormLabelCol} text="手提電話:" />
                    <div className={cssFormInputCol}>
                        <input type="text" readOnly={true}
                            className="form-control-plaintext"
                            value={`${watchAllFields?.MobileCountry}-${watchAllFields?.Mobile}`}
                        ></input>
                    </div>

                    {/* <FBLabel className={cssFormLabelCol} text="聯絡電話:" />
                    <div className={cssFormInputCol}>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <select className="form-select"
                                    {...register("PhoneCountry")}>
                                    <option value=""></option>
                                    <option value="86"> +86 中國內地 </option>
                                    <option value="852"> +852 中國香港 </option>
                                    <option value="853"> +853 中國澳門 </option>
                                    <option value="886"> +886 中國台灣 </option>
                                </select>
                            </div>
                            <input {...register("Phone")}
                                type="text"
                                className="form-control" aria-label="" />
                        </div>
                    </div> */}

                    <FBLabel className={cssFormLabelCol} text="收取結單方式:" required />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="Statement"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            defaultValue={value}
                                            className="col-sm-6 col-xs-12"
                                            Items={[
                                                { name: "E", value: "E", label: "透過電郵及富昌一站通簡稱\"一站通\" (請參閱\"富昌一站通\"之使用條款及細則)" },
                                                { name: "M", value: "M", label: "透過郵寄信件(需收費)" }
                                            ]}
                                            name="Statement" />);
                                }}
                            />

                            <span className={errors?.Statement ? "is-invalid" : ""}></span>
                            <div className="invalid-feedback"> {errors?.Statement?.message} </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <hr className="dot-line" />
                    </div>


                    {userRef.IDCardOrigin === "CHN" && userRef.IPRegion !== "CN" && (
                        <>
                            <FBLabel className={cssFormLabelCol} text="是否持有香港銀行賬戶:" />
                            <div className={cssFormInputCol}>
                                <div className={`row px-3 px-sm-0`}>
                                    <Controller
                                        name="HasHKGBankAcc"
                                        control={control}
                                        render={({
                                            field: { onChange, name, value }
                                        }) => {
                                            return (
                                                <RadioButtonList
                                                    onChange={onChange}
                                                    setValueAs={(v) => v === 'true'}
                                                    defaultValue={watchAllFields.HasHKGBankAcc ? 'true' : 'false'}
                                                    className="col-sm-6 col-xs-12"
                                                    Items={[
                                                        { name: "true", value: true, label: "有" },
                                                        { name: "false", value: false, label: "没有" }
                                                    ]}
                                                    name="HasHKGBankAcc" />);
                                        }}
                                    />

                                    <span className={errors?.HasHKGBankAcc ? "is-invalid" : ""}></span>
                                    <div className="invalid-feedback"> {errors?.HasHKGBankAcc?.message} </div>
                                </div>


                            </div>
                        </>
                    )}



                    {
                        (userRef.IDCardOrigin === "CHN" && userRef.IPRegion !== "CN" && !watchAllFields.HasHKGBankAcc) && 
                        (
                            <div className="col-12 card">
                                <div className="row card-body bg-warning bg-opacity-10">
                                    {/* <UnionPayIdentification /> */}

                                    <div className="col-12">
                                        <h5 className="pt-2 px-0 fb-red"> 內地銀行卡認證 </h5>
                                        <span className="float-start"> 該銀行卡僅用於實名認證，與出入資金無關。 </span>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="銀行名稱:" required />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("BankName")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.BankName)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.BankName?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="銀行卡號:" required />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("UnionPayCardNo")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.UnionPayCardNo)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.UnionPayCardNo?.message} </div>
                                    </div>

                                    <FBLabel className={cssFormLabelCol} text="手機號碼:" required />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("UnionPayMobileNo")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.UnionPayMobileNo)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.UnionPayCardNo?.message} </div>
                                    </div>


                                </div>
                            </div>
                        )
                    }

                    {(watchAllFields.HasHKGBankAcc || userRef.IDCardOrigin !== "CHN" || (userRef.IDCardOrigin === "CHN" && userRef.IPRegion === "CN")) && (
                        <>
                            <div className="col-12 pt-4">
                                <h5 className="pt-2 px-0 fb-red"> 登記香港銀行賬戶 (可供提款及存款) </h5>
                                <span className="float-start"> 如客戶透過轉賬方式進行開戶認證，必須經過以下登記的銀行賬號存款HKD 10,000。 </span>
                            </div>
                            <FBLabel className={cssFormLabelCol} text="銀行名稱:"
                                required={userRef.IDCardOrigin !== "CHN" || (userRef.IDCardOrigin === "CHN" && watchAllFields.HasHKGBankAcc)}
                            />
                            <div className={cssFormInputCol}>
                                <Controller
                                    name="BankCode"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            components={{
                                                NoOptionsMessage: (props) => (
                                                    <components.NoOptionsMessage {...props}>
                                                        找不到相關資料
                                                    </components.NoOptionsMessage>
                                                )
                                            }}
                                            value={BankList.find(c => c.value === field.value)}
                                            onChange={val => {
                                                field.onChange(val?.value || null);
                                                setValue('BankName', (val?.CName || null), { shouldDirty: true });
                                            }}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={
                                                [
                                                    {
                                                        label: '常用銀行',
                                                        options: CommonBankList
                                                    },
                                                    {
                                                        label: '所有銀行',
                                                        options: BankList
                                                    }
                                            ]
                                            }
                                            styles={{
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    borderBottom: '1px dotted pink',
                                                    color: state.selectProps.menuColor,
                                                    padding: 8,
                                                    textAlign: 'left',
                                                })
                                            }}
                                            placeholder="請選擇"
                                            filterOption={createFilter(
                                                {
                                                    ignoreCase: true,
                                                    ignoreAccents: true,
                                                    trim: true,
                                                    matchFrom: 'any',
                                                    stringify: option => `${option.value} ${option.data.CName} ${option.data.EName}`,
                                                })}
                                        />
                                    )}
                                />
                                <span className={errors?.BankCode ? "is-invalid" : ""}></span>
                                <div className="invalid-feedback"> {errors?.BankCode?.message} </div>
                            </div>

                            <FBLabel className={cssFormLabelCol} text="銀行帳號:"
                                required={userRef.IDCardOrigin !== "CHN" || (userRef.IDCardOrigin === "CHN" && watchAllFields.HasHKGBankAcc)}
                            />
                            <div className={cssFormInputCol}>
                                <input
                                    {...register("BankNumber")}
                                    type="text"
                                    className={`form-control ${InvalidFieldStyle(errors?.BankNumber)}`}
                                />
                                <div className="invalid-feedback"> {errors?.BankNumber?.message} </div>
                            </div>

                            <FBLabel className={cssFormLabelCol} text="是否授權登記EDDA快速入金:"
                                required={userRef.IDCardOrigin !== "CHN" || (userRef.IDCardOrigin === "CHN" && watchAllFields.HasHKGBankAcc)}
                            />

                            <div className={cssFormInputCol}>
                                <div className={`row px-3 px-sm-0`}>
                                    <Controller
                                        name="AuthorizeEDDA"
                                        control={control}
                                        render={({
                                            field: { onChange, name, value }
                                        }) => {
                                            return (
                                                <RadioButtonList
                                                    onChange={onChange}
                                                    setValueAs={(v) => v === 'true'}
                                                    defaultValue={value ? 'true' : 'false'}
                                                    className="col-sm-6 col-xs-12"
                                                    Items={[
                                                        { name: "true", value: true, label: "是，並同意接納相關條款" },
                                                        { name: "false", value: false, label: "否" }
                                                    ]} name="AuthorizeEDDA" />);
                                        }}
                                    />

                                    <span className={errors?.AuthorizeEDDA ? "is-invalid" : ""}></span>
                                    <div className="invalid-feedback"> {errors?.AuthorizeEDDA?.message} </div>
                                </div>
                            </div>

                            {watchAllFields.AuthorizeEDDA && (
                                <>
                                    <FBLabel className={cssFormLabelCol} text="EDDA授權金額:" />
                                    <div className={cssFormInputCol}>
                                        <input
                                            {...register("AuthorizeEDDAAmount")}
                                            type="text"
                                            className={`form-control ${InvalidFieldStyle(errors?.AuthorizeEDDAAmount)}`}
                                        />
                                        <div className="invalid-feedback"> {errors?.AuthorizeEDDAAmount?.message} </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </form>


                {/* <button onClick={() => { formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))}}>Submit</button> */}
            </>
        </StepContainer>
    );
}

export default SettlementInfo;