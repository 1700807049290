import React, { useState, useEffect, useCallback } from "react";
import { useUserSettings } from '../UserSettingsProvider';
import StepContainer from '../StepContainer/StepContainer';
import { FFGCheckbox, RadioButtonList } from "../FFGCheckbox/FFGCheckbox";
import { ButtonWrapper, ButtonLabel, ButtonCheckbox } from "./FFGAccountStyle";
import AOSServices from "../../services/AOSServices";
import { FBLabel, PrettyPrintJson, cssFormInput } from '../../services/Utils';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { array } from "yup/lib/locale";


const FFGAccountInfo = (props) => {
    const { userRef, setUserRef } = useUserSettings();

    const validationSchema = Yup.object().shape({
        IntegratedSecAC: Yup.boolean(),
        IntegratedFutAC: Yup.boolean(),
        AccTypeStockOptions: Yup.boolean(),
        AccTypeUSOptions: Yup.boolean(),
        //HasReferrer: Yup.boolean(),
        ReferrerName: Yup.string().nullable(),
            //.when('HasReferrer', (val, schema) => val ? schema.required("請填上相關客戶經理編號或名稱") : schema.notRequired()),
        // TradingPreference: Yup.array().nullable()
        //     .when('HasReferrer', (val, schema) => {
        //         return !val ?
        //             schema.of(Yup.string()).min(1, "請選擇將會主要參與哪項交易") : schema.nullable().notRequired();
        //     }),
        DirectMarketing: Yup.string().nullable().required("請選擇是不否同意接收優惠及推廣資訊"),
        MarketingChannelAll: Yup.boolean(),
        MarketingChannelEmail: Yup.boolean(),
        MarketingChannelMail: Yup.boolean(),
        MarketingChannelSMS: Yup.boolean(),
        MarketingChannelPhone: Yup.boolean(),
    }).test('CustomAccValidation', null, (obj) =>{
        if ( obj.IntegratedSecAC || obj.IntegratedFutAC || obj.AccTypeStockOptions || obj.AccTypeUSOptions ) {
            return true; // everything is fine
          }
          return new Yup.ValidationError('請選擇開戶類型', null, 'AccountTypes');
    });

    const { register, trigger, watch, getValues, setValue, setError, handleSubmit, reset, control,
        formState: { isValid, errors }, clearErrors } = useForm({
            mode: "all",
            resolver: yupResolver(validationSchema),
            defaultValues: {
                IntegratedSecAC: true,
                IntegratedFutAC: false,
                AccTypeStockOptions: false,
                AccTypeUSOptions: false,
                AccTypeOTCOptions: false,
                //HasReferrer: false,
                TradingPreference: [],
                DirectMarketing: "Y",
                MarketingChannelAll: true,
                MarketingChannelEmail: false,
                MarketingChannelMail: false,
                MarketingChannelSMS: false,
                MarketingChannelPhone: false,
            }
        });

    useEffect(() => {
        AOSServices.GetFFGAccountInfo(userRef.RefCode)
            .then((resp) => {
                if (resp && resp.Result) {
                    reset(resp.Info);
                }
            });
    }, [reset]);


    const FBCheckBox = ({ register, children, className = "col-sm-3 col-xs-12", ...props }) => {
        let id = register.name;
        return (
            <ButtonWrapper Lite className={className}>
                <ButtonCheckbox Lite id={id}
                    type="checkbox"
                    {...register}
                ></ButtonCheckbox>
                <ButtonLabel Lite htmlFor={id}>
                    {children}
                    <span className="checkmark"></span>
                </ButtonLabel>
            </ButtonWrapper>
        );
    }

    const goNext = () => {
        clearErrors();
        if (!isValid) {
            trigger(undefined, { shouldFocus: true });
            return new Promise(resolve => resolve(false));
        }
        else {
            const data = getValues();
            return new Promise(resolve => {
                if (!data.IntegratedSecAC && !data.IntegratedFutAC) {
                    alert("請選擇開戶類型");
                    resolve(false);
                } else if (
                    data.DirectMarketing === 'Y' &&
                    !data.MarketingChannelAll &&
                    !data.MarketingChannelEmail &&
                    !data.MarketingChannelMail &&
                    !data.MarketingChannelSMS &&
                    !data.MarketingChannelPhone
                ) {
                    setError("MarketingChannel", {
                        type: "manual",
                        message: "請選擇接收方式"
                    });
                    resolve(false);
                } else {
                    if(!Array.isArray(data.TradingPreference)){
                        let tp = new Array();
                        tp.concat(data.TradingPreference);
                        data.TradingPreference = tp;
                        //console.log(data.TradingPreference);
                    }

                    AOSServices.SaveFFGAccountInfo(userRef.RefCode, data, "EmploymentInfo")
                        .then(res => {
                            if (!res || !res.Result) {
                                alert("提交資料失敗，請重新再試");
                            } else {
                                let FBAcc = [];
                                if (data.IntegratedSecAC) FBAcc.push("S");
                                if (data.IntegratedFutAC) FBAcc.push("F");
                                if (data.AccTypeStockOptions) FBAcc.push("O");
                                if (data.AccTypeUSOptions) FBAcc.push("UO");
                                if (data.AccTypeOTCOptions) FBAcc.push("OTC");
                                setUserRef({ ...userRef, FBAcc });
                            }
                            resolve(res.Result);
                        });
                }
            });
        }
    };

    const watchAllFields = watch();
    const cssFormLabelCol = "col-sm-3 col-xs-12 col-form-label my-0 my-md-2";
    const cssFormInputCol = "col-sm-9 col-xs-12 my-0 my-md-2";

    const handleSecACChanged = (v) => {
        //console.log(v);
        if (!v.target.checked) {
            if (watchAllFields.AccTypeStockOptions) setValue("AccTypeStockOptions", false);
            if (watchAllFields.AccTypeUSOptions) setValue("AccTypeUSOptions", false);
        }
    };

    return (
        <StepContainer showNavigation={true}
            onNext={goNext}
            nextStepName={"FFGAccountInfo"}
            {...props}>

            <div className="row">
                <div className="col-12">
                    <h3 className="pt-3"> 請選擇開戶類型 </h3>
                </div>
            </div>

            {/* <PrettyPrintJson data={watchAllFields}></PrettyPrintJson> */}

            <form className="ffg-form">
                <div className="row">

                    <span className={errors?.AccountTypes ? "is-invalid" : ""}></span>
                    <div className="invalid-feedback"> {errors?.AccountTypes?.message} </div>

                    <ButtonWrapper className="col-sm-6 col-xs-12">
                        <ButtonCheckbox id="IntegratedSecAC"
                            type="checkbox"
                            {...register("IntegratedSecAC", {
                                onChange: handleSecACChanged
                            })}
                        ></ButtonCheckbox>
                        <ButtonLabel htmlFor="IntegratedSecAC">
                            <span className="fs-4">證券綜合賬戶* </span>
                            { userRef.IDCardOrigin == 'CHN' ? (
                                <span style={{ fontSize: "1em" }}>(包括: 港股現金、港股保證金、環球證券賬戶)</span>
                            ) : (
                                <span style={{ fontSize: "1em" }}>(包括: 港股現金、港股保證金、滬/深港通#、環球證券賬戶)</span>
                            )}
                            <span className="checkmark"></span>
                        </ButtonLabel>
                    </ButtonWrapper>

                    <ButtonWrapper className="col-sm-6 col-xs-12">
                        <ButtonCheckbox id="IntegratedFutAC"
                            type="checkbox"
                            {...register("IntegratedFutAC")}
                        ></ButtonCheckbox>
                        <ButtonLabel htmlFor="IntegratedFutAC">
                            <span className="fs-4">期貨綜合賬戶 </span>
                            <span style={{ fontSize: "1em" }}>
                                (包括: 香港期貨 及 環球期貨)
                            </span>
                            <span className="checkmark"></span>
                        </ButtonLabel>
                    </ButtonWrapper>

                    <ButtonWrapper className="col-sm-6 col-xs-12">
                        <ButtonCheckbox id="AccTypeStockOptions"
                            type="checkbox"
                            {...register("AccTypeStockOptions")}
                            disabled={!watchAllFields.IntegratedSecAC}
                        ></ButtonCheckbox>
                        <ButtonLabel htmlFor="AccTypeStockOptions">
                            <span className="fs-4">
                                股票期權賬戶
                            </span>
                            <span style={{ fontSize: "1em" }}>
                                (客戶必需同時開立證券賬戶)
                            </span>
                            <span className="checkmark"></span>
                        </ButtonLabel>
                    </ButtonWrapper>

                    <ButtonWrapper className="col-sm-6 col-xs-12">
                        <ButtonCheckbox id="AccTypeUSOptions"
                            type="checkbox"
                            {...register("AccTypeUSOptions")}
                            disabled={!watchAllFields.IntegratedSecAC}
                        ></ButtonCheckbox>
                        <ButtonLabel htmlFor="AccTypeUSOptions">
                            <span className="fs-4">
                                美股期權賬戶
                            </span>
                            <span style={{ fontSize: "1em" }}>
                                (客戶必需同時開立證券綜合賬戶)
                            </span>
                            <span style={{ fontSize: "0.7em", color: 'red', fontWeight: 'bold' }}>
                            *此賬戶每月資產須達USD1000或以上，否則須支付USD1費用，敬請留意。
                            </span>
                            <span className="checkmark"></span>
                        </ButtonLabel>
                    </ButtonWrapper>

                    <ButtonWrapper className="col-sm-6 col-xs-12">
                        <ButtonCheckbox id="AccTypeOTCOptions"
                            type="checkbox"
                            {...register("AccTypeOTCOptions")}
                            disabled={!watchAllFields.IntegratedSecAC ||
                                !watchAllFields.AccTypeStockOptions ||
                                !watchAllFields.AccTypeUSOptions}
                        ></ButtonCheckbox>
                        <ButtonLabel htmlFor="AccTypeOTCOptions">
                            <span className="fs-4">
                                場外股票期權賬戶
                            </span>
                            <span style={{ fontSize: "1em" }}>
                                (客戶必需同時開立證券綜合賬戶, 環球證券賬戶 及 美股期權賬戶)
                            </span>
                            <span className="checkmark"></span>
                        </ButtonLabel>
                    </ButtonWrapper>

                    <div className="col-sm-12 col-xs-12 my-0 my-md-1">
                        <p className="text-start" style={{fontSize:"0.5em"}}>
                            所有開立之帳戶均有網上買賣服務<br />
                            # 客戶同意富昌證券可根據個人資料收集聲明關於中華通證券(北向交易)服務所載的目的使用客戶的個人資料。<br />
                            *65歲及以上的客戶開立港股保證金賬戶，需要提供最近三個月的投資證明文件。
                        </p>
                    </div>

                    <div className="col-12 my-0 my-md-4">
                        <hr className="dot-line" />
                    </div>
                </div>

                <div className="row">

                    {/* <FBLabel className={cssFormLabelCol} text="是否由 客戶經理/集團員工/推廣活動職員/現有客戶 介紹開戶 ?" required />
                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="HasReferrer"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={onChange}
                                            setValueAs={(v) => v === 'true'}
                                            defaultValue={watchAllFields.HasReferrer ? 'true' : 'false'}
                                            className="col-sm-6 col-xs-12"
                                            Items={[
                                                { name: "false", value: false, label: "否" },
                                                { name: "true", value: true, label: "是" },
                                            ]}
                                            name={"Info.HasReferrer"} />);
                                }}
                            />

                            <span className={errors?.HasReferrer ? "is-invalid" : ""}></span>
                            <div className="invalid-feedback"> {errors?.HasReferrer?.message} </div>
                        </div>
                    </div> */}

                    
                            <FBLabel className={cssFormLabelCol + " text-danger"} text="推薦人名稱/推薦人編號/推薦優惠碼(選填)︰"
                                required={watchAllFields.HasReferrer} />
                            <div className={cssFormInputCol}>
                                <input {...register("ReferrerName")}
                                    type="text"
                                    className={cssFormInput(errors?.ReferrerName?.message)}
                                />
                                {/* <div className="invalid-feedback"> {errors?.ReferrerName?.message} </div> */}
                            </div>
                    

                    

                    <FBLabel className={cssFormLabelCol} text="優惠及推廣資訊"
                        required />

                    <div className={cssFormInputCol}>
                        <div className={`row px-3 px-sm-0`}>
                            <Controller
                                name="DirectMarketing"
                                control={control}
                                render={({
                                    field: { onChange, name, value }
                                }) => {
                                    return (
                                        <RadioButtonList
                                            onChange={(e) => {
                                                if (e === 'N') {
                                                    setValue("MarketingChannelAll", false);
                                                    setValue("MarketingChannelEmail", false);
                                                    setValue("MarketingChannelMail", false);
                                                    setValue("MarketingChannelSMS", false);
                                                    setValue("MarketingChannelPhone", false);
                                                }
                                                onChange(e);
                                            }}
                                            className="col-sm-6 col-xs-12"
                                            defaultValue={watchAllFields.DirectMarketing}
                                            Items={[
                                                { name: "Y", value: "Y", label: "閣下同意透過以下途徑接收本集團之優惠通知" },
                                                { name: "N", value: "N", label: "閣下不同意接收本集團之優惠通知" },
                                            ]}
                                            name={"Info.DirectMarketing"} />);
                                }}
                            />

                            <span className={errors?.DirectMarketing ? "is-invalid" : ""}></span>
                            <div className="invalid-feedback"> {errors?.DirectMarketing?.message} </div>

                            {watchAllFields.DirectMarketing === 'Y' && (
                                <>
                                    <FBCheckBox className="col-sm-12 col-xs-12" register={register("MarketingChannelAll", {
                                        onChange: (e) => {
                                            var isChecked = e.target.checked;
                                            if (isChecked) {
                                                setValue("MarketingChannelEmail", !isChecked);
                                                setValue("MarketingChannelMail", !isChecked);
                                                setValue("MarketingChannelSMS", !isChecked);
                                                setValue("MarketingChannelPhone", !isChecked);
                                            }
                                        }
                                    })}> 所有途徑 </FBCheckBox>
                                    <FBCheckBox className="col-sm-4 col-xs-6" register={register("MarketingChannelSMS", { disabled: watchAllFields.MarketingChannelAll })}> 短訊及即時通訊應用程式 </FBCheckBox>
                                    <FBCheckBox className="col-sm-3 col-xs-6" register={register("MarketingChannelEmail", { disabled: watchAllFields.MarketingChannelAll })}> 電郵 </FBCheckBox>
                                    <FBCheckBox className="col-sm-3 col-xs-6" register={register("MarketingChannelMail", { disabled: watchAllFields.MarketingChannelAll })}> 郵寄 </FBCheckBox>
                                    <FBCheckBox className="col-sm-2 col-xs-6" register={register("MarketingChannelPhone", { disabled: watchAllFields.MarketingChannelAll })}> 電話 </FBCheckBox>
                                </>
                            )}

                            <span className={errors?.MarketingChannel ? "is-invalid" : ""}></span>
                            <div className="invalid-feedback"> {errors?.MarketingChannel?.message} </div>

                        </div>

                    </div>

                    <div className="col-12">
                    </div>
                </div>


            </form>

            <div className="row">


            </div>

        </StepContainer>);
};

export default FFGAccountInfo;