import React, {useEffect} from "react";
import "./StepContainer.css";

const StepContainer = (props) => {
    const [IsProcessingNext, setIsProcessingNext] = React.useState(false);

    const { showNavigation, goToNamedStep, prevStepName, nextStepName, onNext, nextDisabled, prevDisable } = props;

    const prevStep = () => {
        if (prevStepName && goToNamedStep) goToNamedStep(prevStepName);
    };

    const nextStep = async () => {
        setIsProcessingNext(true);
        if (nextStepName && goToNamedStep) {
            if (!onNext)
                goToNamedStep(nextStepName);
            else {
                let r = await onNext();
                //console.log(r, "onNext - result");
                if (r === true) {
                    goToNamedStep(nextStepName);
                }
            }
        }
        setIsProcessingNext(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (<div className="container">
        <div className="row">
            <div className="col-12 bg-black py-2 m-0"></div>
        </div>
        {props.children}
        <div className="row">
            <div className="col-12 py-3 pt-md-5 pb-md-2 m-0">
                {showNavigation && (<div className="d-grid gap-2 d-md-flex px-5">
                    {prevStepName && (
                        <button type="button"
                            className="btn btn-outline-wizard col-md-5 col-sm-6"
                            disabled={prevDisable}
                            onClick={() => { prevStep() }}> 上一歩 </button>)}
                    {nextStepName && (
                        <button type="button"
                            className={`btn btn-wizard ${prevStepName ? "col-sm-6 col-md-7" : "col-sm-12 col-md-12"}`}
                            disabled={nextDisabled || IsProcessingNext}
                            onClick={() => { nextStep() }}>
                            {IsProcessingNext && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            下一歩
                        </button>
                    )}
                </div>)}

                <span className="text-center d-block py-2">
                    富昌金融集團
                </span>
            </div>
        </div>

    </div>
    );
}

export default StepContainer;